import Grid from '@mui/material/Grid';
import { Translator } from '~/types/Translator';
import { useFormContext, UseFieldArrayRemove } from 'react-hook-form';
import SelectedSongwriterEdit from './SelectedSongwriterEdit';
import SelectedSongwriterSaved from './SelectedSongwriterSaved';

interface SelectedSongwriterProps extends Translator {
  i: number;
  remove: UseFieldArrayRemove;
}

export default function SelectedSongwriter({
  i = 0,
  remove,
  t,
}: SelectedSongwriterProps) {
  const { watch } = useFormContext();
  const edit = watch(`cowriters.${i}.edit`);

  return (
    <Grid
      container
      direction={edit ? 'row' : 'column'}
      xs={12}
      gap={3}
      justifyContent="space-between"
      alignItems="center"
      border="black 1px solid"
      borderRadius="10px"
    >
      {!edit ? <SelectedSongwriterSaved i={i} remove={remove} /> : ''}
      {edit ? <SelectedSongwriterEdit i={i} t={t} remove={remove} /> : ''}
    </Grid>
  );
}
