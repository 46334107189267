import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import FormInput from '~/base/components/FormInput';
import FormSelect from '~/base/components/FormSelect';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import languageOptions from '~/constants/languages';
import { Translator } from '~/types/Translator';

export default function Lyrics({ t }: Translator) {
  const lyricGuidelines = [] as Array<string>;
  const { getValues } = useFormContext();
  const LYRIC_GUIDELINE_COUNT = 14;
  for (let i = 1; i <= LYRIC_GUIDELINE_COUNT; i += 1) {
    lyricGuidelines.push(t(`form.copy.lyricGuideline${i}`));
  }
  const { id: songId } = getValues();
  return (
    <>
      <SectionTitle>{t('page.sections.lyrics')}</SectionTitle>
      <Grid container item>
        <Grid container item xs={12} rowGap={2}>
          {!songId && (
            <FormSelect
              id="lyrics-language"
              label={t('form.labels.language')}
              name="language"
              options={languageOptions}
              sx={{ width: '100%' }}
            />
          )}
          <FormInput
            id="full-lyrics"
            label={t('form.labels.fullLyrics')}
            name="fullLyrics"
            sx={{ width: '100%' }}
            rows={4}
            multiline
            inputFullWidth
          />
          <FormInput
            id="chorus-lyrics"
            label={t('form.labels.chorusLyrics')}
            name="chorusLyrics"
            sx={{ width: '100%' }}
            rows={4}
            multiline
            inputFullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
