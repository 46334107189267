import { ApolloError, useLazyQuery } from '@apollo/client';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import SectionTipCard from '~/base/components/SectionTipCard';
import usePageTitle from '~/hooks/usePageTitle';
import SignupDiscountDisplay from '~/signup/components/SignupDiscountDisplay/SignupDiscountDisplay';
import SignupDiscountLink from '~/signup/components/SignupDiscountLink/SignupDiscountLink';
import SignupDiscountLinkEntry from '~/signup/components/SignupDiscountLinkEntry/SignupDiscountLinkEntry';
import { PER_SONGWRITER_COST } from '~/signup/constants/signupConstants';
import { Translator } from '~/types/Translator';
import {
  SubscriptionPlanQuery,
  VerifyDiscountCodeDocument,
  VerifyDiscountCodeQuery,
  useAddSongwriterCreditsMutation,
  useSubscriptionPlanQuery,
} from '~/types/generated/graphql';
import LegacyClientInformation from './LegacyClientInformation/LegacyClientInformation';
import SongwriterIntervalButton from './SongwriterIntervalButton';

function IncreaseSongwritersPage({ t }: Translator) {
  usePageTitle(t('page.increase-songwriters.title'));

  const MESSAGE_REDIRECT_TIMEOUT = 3000;
  const increaseSongwritersForm = useForm();
  const navigate = useNavigate();

  const [increaseCount, setIncreaseCount] = useState<number>(1);
  const [isLegacyClient, setIsLegacyClient] = useState<boolean>(false);
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');

  const [totalDiscountPercent, setTotalDiscountPercent] = useState<number>(0);
  const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState<number>(
    1 * PER_SONGWRITER_COST,
  );

  const [invalidCode, setInvalidCode] = useState<boolean>(false);
  const [discountMode, setDiscountMode] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>('');
  const [editDiscountCode, setEditDiscountCode] = useState<string>('');

  const [addSongwriterCredits, { loading: addCreditLoading }] =
    useAddSongwriterCreditsMutation({
      fetchPolicy: 'no-cache',
    });

  const handleSubmitComplete = () => {
    setWasSaved(true);
    setTimeout(() => {
      navigate('/songwriters/manage');
    }, MESSAGE_REDIRECT_TIMEOUT);
  };

  const handleFormSubmitError = (error: ApolloError) => {
    setProcessError(error.message);
    setWasSaved(false);
  };

  const handleFormError = async () => {
    setProcessError(t('sections.account-information.about-me.errors.fields'));
    setWasSaved(false);
  };

  const handleFormSubmit = async (values: FieldValues) => {
    setWasSaved(false);
    setProcessError('');

    if (await increaseSongwritersForm.trigger()) {
      // Full submission variables.
      const submissionVariables = {
        credits: values.credits,
        discountCode: values.discountCode,
      };

      // Submit Mutation.
      addSongwriterCredits({
        variables: submissionVariables,
        onCompleted: handleSubmitComplete,
        onError: handleFormSubmitError,
      });
    }
  };

  const updateFormValues = (songwriters: number, code: string) => {
    increaseSongwritersForm.setValue('credits', songwriters);
    increaseSongwritersForm.setValue('discountCode', code);
  };

  const updateTotalPrice = (writerCount: number, discountPercent: number) => {
    const totalCost = writerCount * PER_SONGWRITER_COST;
    const totalDiscount = discountPercent * totalCost;
    const totalCostWithDiscount = totalCost - totalDiscount;

    setTotalPriceWithDiscount(totalCostWithDiscount);
    updateFormValues(writerCount, editDiscountCode);
  };

  const afterCheckDiscountCodeComplete = (
    res: VerifyDiscountCodeQuery | undefined,
  ) => {
    if (res?.verifyDiscountCode?.isValid) {
      const valueFromRequest = res?.verifyDiscountCode?.discount;
      setTotalDiscountPercent(valueFromRequest);
      updateTotalPrice(increaseCount, valueFromRequest);
      setDiscountMode(!discountMode);
    } else {
      setInvalidCode(true);
    }
  };

  const [checkDiscountCode, { loading: discountCodeLoading, error }] =
    useLazyQuery<VerifyDiscountCodeQuery>(VerifyDiscountCodeDocument, {
      fetchPolicy: 'no-cache',
      variables: { discountCode: editDiscountCode },
      onCompleted: (d) => {
        afterCheckDiscountCodeComplete(d);
      },
    });

  const handleDiscountCloseClick = () => {
    increaseSongwritersForm.reset(
      { discountCode: '', credits: increaseCount },
      {
        keepErrors: false,
        keepIsValid: true,
        keepTouched: true,
      },
    );
    setInvalidCode(false);
    setDiscountMode(!discountMode);
    setEditDiscountCode('');
    setDiscountCode('');
  };

  const handleDiscountClick = () => {
    setDiscountMode(!discountMode);
  };

  const handleDiscountApplyClick = () => {
    setInvalidCode(false);
    setDiscountCode(editDiscountCode);
    checkDiscountCode();
  };

  const handleRemoveDiscountClick = () => {
    setTotalDiscountPercent(0);

    updateTotalPrice(increaseCount, 0);

    setDiscountCode('');
    setEditDiscountCode('');
    setDiscountMode(false);
  };

  const handleEditDiscountCodeChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditDiscountCode(e.target.value);
  };

  const handleSubscriptionPlanLoaded = (data: SubscriptionPlanQuery) => {
    // Set if client is a legacy client or not.
    setIsLegacyClient(
      data?.loggedInSongtrustUser?.subscriptionPlan?.planCode !== 'SINGLE_PLAN',
    );

    // Users with a Single Plan who do not have a stripe CC on file with last 4, redirect to billing information.
    if (
      data?.loggedInSongtrustUser?.subscriptionPlan?.planCode ===
        'SINGLE_PLAN' &&
      (data?.loggedInSongtrustUser?.billingInformation === undefined ||
        data?.loggedInSongtrustUser?.billingInformation?.last4 === undefined ||
        data?.loggedInSongtrustUser?.billingInformation?.last4?.length !== 4)
    ) {
      navigate('/account/billing-information');
    }
  };

  const { loading: subscriptionPlanLoading } = useSubscriptionPlanQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: handleSubscriptionPlanLoaded,
  });

  useEffect(() => {
    increaseSongwritersForm.setValue('credits', 1);
  }, []);

  return (
    <Container data-testid="increase-songwriters-page">
      <Grid
        container
        sx={{
          flexDirection: {
            xs: 'column',
          },
        }}
      >
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              pt: { md: '2rem' },
              pb: '3rem',
            }}
          >
            <Typography
              data-testid="increase-songwriters-title"
              variant="h1"
              component="h1"
            >
              {isLegacyClient && t('page.increase-songwriters.legacy.title')}
              {!isLegacyClient && t('page.increase-songwriters.title')}
            </Typography>
          </Box>
        </Grid>

        <Grid
          container
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
        >
          <Grid item xs={12} md={9}>
            {subscriptionPlanLoading && <LoadingIndicator size={50} />}
            {!subscriptionPlanLoading && (
              <Box
                data-testid="increase-songwriters-content"
                sx={{
                  pl: { md: '2rem' },
                  pr: { md: '4rem' },
                  pb: { xs: '2rem', md: '0' },
                }}
              >
                {isLegacyClient && <LegacyClientInformation t={t} />}
                {!isLegacyClient && (
                  <FormProvider {...increaseSongwritersForm}>
                    <form
                      id="accountInformationForm"
                      onSubmit={increaseSongwritersForm.handleSubmit(
                        handleFormSubmit,
                        handleFormError,
                      )}
                      onChange={() => {
                        setWasSaved(false);
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {!wasSaved && (
                            <FormBanner
                              text={processError}
                              type={FormBannerType.ERROR}
                            />
                          )}
                          {wasSaved && (
                            <FormBanner
                              text={t('page.increase-songwriters.success')}
                              type={FormBannerType.SUCCESS}
                              time={MESSAGE_REDIRECT_TIMEOUT}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" component="span">
                            {t('page.increase-songwriters.description')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid
                            container
                            alignContent="center"
                            alignItems="center"
                            justifyContent="space-around"
                          >
                            <Grid item xs={4}>
                              <Grid
                                container
                                justifyContent="space-around"
                                sx={{ pt: '1rem' }}
                              >
                                <SongwriterIntervalButton
                                  text="-"
                                  onClick={() => {
                                    const currentCredits =
                                      increaseSongwritersForm.getValues(
                                        'credits',
                                      );

                                    if (currentCredits > 1) {
                                      increaseSongwritersForm.setValue(
                                        'credits',
                                        currentCredits - 1,
                                      );
                                      setIncreaseCount(increaseCount - 1);
                                      updateTotalPrice(
                                        increaseCount - 1,
                                        totalDiscountPercent,
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid container justifyContent="space-around">
                                <FormInput
                                  sx={{
                                    width: '100%',
                                  }}
                                  id="credits"
                                  data-testid="songwriter-credits"
                                  name="credits"
                                  disabled
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                container
                                justifyContent="space-around"
                                sx={{ pt: '1rem' }}
                              >
                                <SongwriterIntervalButton
                                  text="+"
                                  backgroundColor="#7FC832"
                                  onClick={() => {
                                    const currentCredits =
                                      increaseSongwritersForm.getValues(
                                        'credits',
                                      );

                                    if (currentCredits < 99) {
                                      increaseSongwritersForm.setValue(
                                        'credits',
                                        currentCredits + 1,
                                      );
                                      setIncreaseCount(increaseCount + 1);
                                      updateTotalPrice(
                                        increaseCount + 1,
                                        totalDiscountPercent,
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant="h5" component="h5">
                                {t('page.increase-songwriters.how-many')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ pb: '2rem' }}>
                              <Typography variant="body1" component="div">
                                {`${t('page.increase-songwriters.purchase')} `}
                                <strong>{increaseCount}</strong>
                                {increaseCount > 1
                                  ? ` ${t(
                                      'page.increase-songwriters.new-songwriters',
                                    )} `
                                  : ` ${t(
                                      'page.increase-songwriters.new-songwriter',
                                    )} `}
                                <strong>${totalPriceWithDiscount}</strong>
                              </Typography>
                            </Grid>
                            {!discountMode && !discountCode && (
                              <SignupDiscountLink
                                t={t}
                                tPrefix="page.increase-songwriters."
                                handleDiscountClick={handleDiscountClick}
                              />
                            )}

                            {discountMode && (
                              <SignupDiscountLinkEntry
                                t={t}
                                tPrefix="page.increase-songwriters."
                                loading={discountCodeLoading}
                                invalidCode={invalidCode}
                                editDiscountCode={editDiscountCode}
                                handleEditDiscountCodeChanges={
                                  handleEditDiscountCodeChanges
                                }
                                handleDiscountApplyClick={
                                  handleDiscountApplyClick
                                }
                                handleDiscountCloseClick={
                                  handleDiscountCloseClick
                                }
                                error={error}
                              />
                            )}

                            {!discountMode && discountCode && (
                              <SignupDiscountDisplay
                                t={t}
                                tPrefix="page.increase-songwriters."
                                discountCode={discountCode}
                                totalDiscountPercent={totalDiscountPercent}
                                handleRemoveDiscountClick={
                                  handleRemoveDiscountClick
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              pt: 12,
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              data-testid="button-back"
                              sx={{ mr: 1, pl: '3rem', pr: '3rem' }}
                              onClick={() => {
                                navigate('/songwriters/manage');
                              }}
                            >
                              {t('page.increase-songwriters.back')}
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            {!addCreditLoading && !subscriptionPlanLoading && (
                              <Button
                                variant="contained"
                                color="secondary"
                                data-testid="button-finish"
                                type="submit"
                                name="button-finish"
                                sx={{ pl: '2rem', pr: '2rem' }}
                              >
                                {t('page.increase-songwriters.purchase')}
                              </Button>
                            )}
                            {(addCreditLoading || subscriptionPlanLoading) && (
                              <Grid item>
                                <LoadingIndicator size={30} />
                              </Grid>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </FormProvider>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt: { md: '.5rem' } }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <SectionTipCard
                  title={t('page.increase-songwriters.tip.title')}
                >
                  <>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ pb: '1rem' }}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'page.increase-songwriters.tip.content',
                        ) as string,
                      }}
                    />
                    <Typography
                      variant="body1"
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'page.increase-songwriters.tip.content-continued',
                        ) as string,
                      }}
                    />
                  </>
                </SectionTipCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default IncreaseSongwritersPage;
