import { useReactiveVar } from '@apollo/client';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { currentSessionTokenVar } from '~/cache';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import SignupPageForm from '../SignupPageForm/SignupPageForm';

function SignupPage({ t }: Translator) {
  usePageTitle(t('page.title'));
  const navigate = useNavigate();

  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const signupFormMethods = useForm();

  const [stripePromise] = useState(() =>
    loadStripe(import.meta.env.VITE_STRIPE_PK),
  );

  useEffect(() => {
    document.title = `${t('page.title')} | ${import.meta.env.VITE_APP_TITLE}`;
    if (sessionToken) navigate('/dashboard');
  }, []);

  useEffect(() => {
    signupFormMethods.setValue('firstname', '', { shouldDirty: true });
    signupFormMethods.setFocus('firstname');
  }, []);

  return (
    <div className="signup-page" data-testid="signup-page">
      <Container>
        <Grid item xs={12} md={12}>
          <Typography
            data-testid="signup-title"
            variant="h1"
            component="h1"
            sx={{
              paddingTop: { xs: '2rem' },
            }}
          >
            {t('page.title')}
          </Typography>
        </Grid>
        <Grid
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              md: 'row',
            },
          }}
          container
        >
          <Grid item xs={12} md={12}>
            <Box
              data-testid="signup-content"
              sx={{
                paddingTop: { xs: '2rem' },
                paddingLeft: { md: '2rem' },
                paddingBottom: { xs: 'none', md: '2rem' },
              }}
            >
              <Typography variant="h4" component="h2">
                {t('page.content.subtitle')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  paddingTop: { xs: '1rem' },
                }}
              >
                {t('page.content.intro')}
              </Typography>
            </Box>
          </Grid>

          <FormProvider {...signupFormMethods}>
            {!stripePromise && <LoadingIndicator size={50} />}
            {stripePromise && (
              <Elements stripe={stripePromise}>
                <SignupPageForm t={t} />
              </Elements>
            )}
          </FormProvider>
        </Grid>
      </Container>
    </div>
  );
}

export default SignupPage;
