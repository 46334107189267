import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { InputHTMLAttributes } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormInput from '~/base/components/FormInput';
import FormTooltip from '~/base/components/FormTooltip';
import RichTooltipContent from '~/base/components/RichTooltipContent';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Translator } from '~/types/Translator';

export default function SongTitle({ t }: Translator) {
  const { control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'alternateTitles',
    control,
  });
  const alternatePoints = [1, 2, 3, 4].reduce((acc: string[], point) => {
    return acc.concat(t(`form.copy.alternateTitle${point}`));
  }, []);

  return (
    <Grid container>
      <SectionTitle>{t('form.titles.information')}</SectionTitle>
      <Grid container item xs={12} md={6.33} gap={3}>
        <FormInput
          id="song-title"
          label={t('form.labels.title')}
          name="title"
          sx={{
            width: '100%',
          }}
          inputProps={{ 'data-testid': 'song-title-input' }}
          inputFullWidth
          displayRequired
          tooltipLabel={t('form.labels.whyAlternateTitle')}
          tooltip={
            <RichTooltipContent
              title={t('form.titles.alternateTitle')}
              contents={alternatePoints}
            />
          }
          required={{
            value: true,
            message: t('form.errors.songTitleRequired'),
          }}
          clearErrorOnFocus
        />
        <Button
          data-testid="add-alternate-title"
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={() => append({ title: '' })}
        >
          {t('form.buttons.alternateTitle')}
        </Button>
        <Grid container item xs={12} gap={1}>
          {fields.map((field, i) => {
            return (
              <Grid key={field.id} container item xs={12} direction="row">
                <FormInput
                  key={field.id}
                  id={`alternateTitles-${i}-title`}
                  name={`alternateTitles.${i}.title`}
                  label={t('form.labels.alternateTitle')}
                  sx={{ flexGrow: '1' }}
                  inputProps={{ 'data-testid': `alternate-title-${i}` }}
                  inputFullWidth
                  clearErrorOnFocus
                />
                <IconButton
                  data-testid={`delete-alternate-title-${i}`}
                  onClick={() => remove(i)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            );
          })}
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={
                {
                  'data-testid': 'public-domain-checkbox',
                } as InputHTMLAttributes<HTMLInputElement>
              }
              onChange={({ target: { checked } }) =>
                setValue('publicDomain', checked)
              }
            />
          }
          label={
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>{t('form.labels.public')}</Grid>
              <Grid item>
                <FormTooltip
                  tooltip={
                    <RichTooltipContent
                      title={t('form.titles.publicDomain')}
                      contents={[
                        t('form.copy.publicDomain1'),
                        t('form.copy.publicDomain2'),
                      ]}
                    />
                  }
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
}
