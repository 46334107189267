import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

/**
 * Props for SectionTitle component.
 */
interface SectionTitleProps {
  /**
   * The content of the section title.
   */
  children: ReactNode;
  /**
   * Optional action element to display on the right side.
   */
  action?: ReactNode;
}

/**
 * SectionTitle component renders a title with an optional action element.
 * @param {SectionTitleProps} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
export default function SectionTitle({
  children,
  action = '',
}: SectionTitleProps): JSX.Element {
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      sx={{
        my: 3,
        pb: 1,
        width: '100%',
        borderBottom: 'black 1px solid',
      }}
    >
      <Typography variant="h2">{children}</Typography>
      <Grid container item xs={6} justifyContent="flex-end">
        {action}
      </Grid>
    </Grid>
  );
}
