/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { NetworkStatus } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { T } from '~/types/Translator';

import { DialogContent } from '@mui/material';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  useAddYoutubeChannelMutation,
  useRemoveYoutubeChannelMutation,
  useYoutubeChannelsQuery,
} from '~/types/generated/graphql';
import ErrorAlert from '~/youtube/components/ErrorAlert';
import YOUTUBE_CHANNELS from '~/youtube/queries/youtubeChannels.graphql';

export default function YoutubeAllowlistedModal({
  open,
  t,
}: {
  open: boolean;
  t: T;
}) {
  const navigate = useNavigate();

  const handleClose = () => navigate('/youtube');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [deletingChannels, setDeletingChannels] = useState<{
    [key: string]: boolean;
  }>({});

  const formMethods = useForm({ mode: 'onBlur' });
  const { error: urlError } = formMethods.getFieldState('channelUrl');
  const urlValue = formMethods.watch('channelUrl');

  const [addYoutubeChannel, { error: responseError, data: responseData }] =
    useAddYoutubeChannelMutation({
      onCompleted() {
        formMethods.resetField('channelUrl');
      },
      refetchQueries: [
        {
          query: YOUTUBE_CHANNELS,
          variables: {
            offset: pagination.pageIndex * pagination.pageSize,
            first: pagination.pageSize,
          },
        },
      ],
    });
  const [removeYoutubeChannel, { error: removeError, data: removeData }] =
    useRemoveYoutubeChannelMutation({
      refetchQueries: [
        {
          query: YOUTUBE_CHANNELS,
          variables: {
            offset: pagination.pageIndex * pagination.pageSize,
            first: pagination.pageSize,
          },
        },
      ],
    });
  const { data, error, loading, networkStatus } = useYoutubeChannelsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: pagination.pageIndex * pagination.pageSize,
      first: pagination.pageSize,
    },
  });

  const columns = useMemo<MRT_ColumnDef<Record<string, unknown>>[]>(
    () => [
      {
        id: 'delete-channel',
        accessorKey: 'channelId',
        header: 'Remove Channel',
        Cell(cell) {
          const v = cell.cell.getValue() as string;
          return (
            <Button
              data-testid="channel-delete-button"
              disabled={deletingChannels[v]}
              startIcon={<DeleteIcon />}
              onClick={() => {
                setDeletingChannels((state) => ({
                  ...state,
                  [v]: true,
                }));
                removeYoutubeChannel({
                  variables: {
                    channelId: v,
                  },
                  onCompleted() {
                    setDeletingChannels((state) => ({
                      ...state,
                      [v]: false,
                    }));
                  },
                  onError() {
                    return undefined;
                  },
                });
              }}
            >
              {t('page.allowlisted.delete')}
            </Button>
          );
        },
      },
      { accessorKey: 'title', header: 'Channel Name' },
      { accessorKey: 'channelId', header: 'Channel ID' },
    ],
    [deletingChannels],
  );

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullScreen={!md}
      fullWidth
    >
      <DialogTitle>{t('page.allowlisted.title')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (c) => c.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid
          container
          item
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'center', md: 'normal' }}
          rowGap={3}
        >
          <Grid item sm={5}>
            {!!responseError?.networkError ||
            !!responseData?.addYoutubeChannel?.errors?.length ||
            !!responseData?.addYoutubeChannel?.channel ? (
              <FormBanner
                type={
                  Number(responseData?.addYoutubeChannel?.errors?.length)
                    ? FormBannerType.ERROR
                    : FormBannerType.SUCCESS
                }
                time={10000}
                text={
                  !!responseError?.networkError ||
                  !!responseData?.addYoutubeChannel?.errors?.length
                    ? t('page.allowlisted.generalError')
                    : t('page.allowlisted.success')
                }
              />
            ) : (
              ''
            )}
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit(({ channelUrl }) =>
                  addYoutubeChannel({
                    variables: {
                      // Format the URL correctly if URL is approximately correct
                      channelUrl: channelUrl.replace(
                        /^(https:\/\/)?(www\.)?youtube\.com\/channel\//i,
                        'https://www.youtube.com/channel/',
                      ),
                    },
                  }),
                )}
              >
                <Grid container item direction="column" sm={9} rowGap={3}>
                  <ErrorAlert
                    t={t}
                    translatePath="page.allowlisted.youtubeURLError"
                    error={!!urlError && !!formMethods.getValues('channelUrl')}
                  />
                  {responseData?.addYoutubeChannel?.errors?.length ? (
                    <ErrorAlert
                      t={t}
                      errorMessage={responseData?.addYoutubeChannel?.errors?.map(
                        (e) => {
                          return <li>{e?.error}</li>;
                        },
                      )}
                      error={!!responseData?.addYoutubeChannel?.errors?.length}
                      time={10000}
                    />
                  ) : (
                    ''
                  )}
                  {responseError?.networkError ? (
                    <ErrorAlert
                      t={t}
                      errorMessage={responseError?.networkError?.message || ''}
                      error={!!responseError?.networkError}
                      time={10000}
                    />
                  ) : (
                    ''
                  )}
                  <Typography variant="h2" component="h3">
                    Enter YouTube Channel
                  </Typography>
                  <FormInput
                    id="channel-url-input"
                    inputProps={{ 'data-testid': 'url-input' }}
                    label={t('page.allowlisted.youtubeChannelURL')}
                    name="channelUrl"
                    placeholder="https://www.youtube.com/..."
                    tooltip={t('page.allowlisted.youtubeURLTooltip')}
                    inputFullWidth
                    pattern={{
                      value:
                        /(^$|^(https:\/\/)?(www\.)?youtube\.com\/(channel\/[\w'\-_]+|@[\w'\-_]+)$)/i,
                      message: '',
                    }}
                  />
                  <Typography>
                    By submitting your channel, you agree that Songtrust will
                    not claim videos from your channel. We suggest joining the
                    YouTube Partner Program to collect these royalties directly.
                  </Typography>
                  <Button
                    id="channel-submit-button"
                    data-testid="channel-submit-button"
                    type="submit"
                    variant="contained"
                    disabled={!formMethods.formState.isValid || !urlValue}
                  >
                    Add Channel
                  </Button>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
          <Grid
            data-testid="channel-list"
            item
            sm={7}
            sx={{ paddingTop: '2rem' }}
          >
            <Grid item sm={12}>
              {removeData?.removeYoutubeChannel?.errors?.length === 0 ? (
                <FormBanner
                  type={
                    Number(removeData?.removeYoutubeChannel.errors?.length)
                      ? FormBannerType.ERROR
                      : FormBannerType.SUCCESS
                  }
                  time={10000}
                  text={t('page.allowlisted.deleteSuccess')}
                />
              ) : (
                ''
              )}
            </Grid>
            {loading || networkStatus === NetworkStatus.refetch ? (
              <LoadingIndicator size={100} />
            ) : (
              <>
                <ErrorAlert
                  t={t}
                  errorMessage={removeData?.removeYoutubeChannel?.errors?.map(
                    (e) => {
                      return <li>{e?.error}</li>;
                    },
                  )}
                  error={!!removeData?.removeYoutubeChannel?.errors?.length}
                  time={10000}
                />
                <ErrorAlert
                  t={t}
                  errorMessage={removeError?.networkError?.message || ''}
                  error={!!removeError?.networkError}
                  time={10000}
                />
                <MaterialReactTable
                  columns={columns}
                  data={
                    (data?.youtubeChannels?.edges?.map(
                      (edge) => edge?.node,
                    ) as unknown as Record<string, string>[]) || []
                  }
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableColumnDragging={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  rowCount={data?.youtubeChannels?.totalCount || 0}
                  muiTableContainerProps={{
                    sx: {
                      maxWidth: {
                        xs: '80vw',
                        sm: '100%',
                      },
                    },
                  }}
                  muiTableHeadProps={{
                    sx: {
                      '& tr': {
                        boxShadow: 'none',
                      },
                      '& th': {
                        borderBottom: '1px #A4A4A4 solid',
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      boxShadow: 'none',
                    },
                  }}
                  state={{
                    isLoading: loading,
                    pagination,
                    showAlertBanner: error !== undefined,
                    showProgressBars: loading,
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
