import { useReactiveVar } from '@apollo/client';
import { Container } from '@mui/material';
import usePageTracking from '~/base/hooks/usePageTracking';
import { currentSessionTokenVar } from '~/cache';
import useRawScript from '~/hooks/useRawScript';
import useScript from '~/hooks/useScript';
import FooterLoggedIn from '../FooterLoggedIn/FooterLoggedIn';
import FooterLoggedOut from '../FooterLoggedOut/FooterLoggedOut';

function Footer() {
  const sessionToken = useReactiveVar(currentSessionTokenVar);

  if (
    ['prod', 'qual', 'stag'].includes(import.meta.env.VITE_ENV) ||
    import.meta.env.VITE_ENABLE_DEV_TRACKING_SCRIPTS === 'true'
  ) {
    // Mouseflow
    useRawScript('window._mfq = window._mfq || [];');
    useScript(import.meta.env.VITE_MOUSEFLOW_URL);

    // Sentry
    useScript(import.meta.env.VITE_SENTRY_DSN);

    // Hubspot
    useScript(import.meta.env.VITE_HUBSPOT_URL);

    // Twitter
    useScript('https://platform.twitter.com/oct.js', false);
    useRawScript("if (window.twttr) { twttr.conversion.trackPid('l47c7') }");

    // Google Analytics
    usePageTracking();
  }

  usePageTracking();

  return (
    <footer data-testid="footer">
      <Container
        className="footer"
        sx={{
          borderTop: !sessionToken ? '0.063rem solid #E0E0E0' : 'none',
          paddingTop: '2.125rem',
          marginTop: '6.25rem',
        }}
      >
        {sessionToken && <FooterLoggedIn />}
        {!sessionToken && <FooterLoggedOut />}
      </Container>
    </footer>
  );
}

export default Footer;
