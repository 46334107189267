import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import ChangePasswordSection from '../ChangePasswordSection/ChangePasswordSection';

function ForceChangePasswordPage({ t }: Translator) {
  usePageTitle(t('sections.change-password.title'));

  return (
    <div data-testid="force-change-password-page">
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                pt: { md: '2rem' },
                pl: { md: '5rem' },
                pr: { md: '5rem' },
              }}
            >
              <Typography
                data-testid="change-password-page-title"
                variant="h1"
                component="h1"
              >
                {t('sections.force-change-password.title')}
              </Typography>
              <Typography
                data-testid="change-password-page-title"
                variant="body1"
                component="p"
              >
                {t('sections.force-change-password.content')}{' '}
                <Link
                  to={t('sections.force-change-password.support-link')}
                  target="_blank"
                >
                  {t('sections.force-change-password.support-team')}.
                </Link>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box
              sx={{
                pt: { md: '2rem' },
                pl: { md: '5rem' },
                pr: { md: '5rem' },
              }}
            >
              <ChangePasswordSection t={t} dashboardRedirect />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ForceChangePasswordPage;
