import { Container } from '@mui/material';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';

import HeaderExternal from '~/base/components/Header/HeaderExternal';
import HeaderInternal from '~/base/components/Header/HeaderInternal';
import HeaderLandingPage from '~/base/components/Header/HeaderLandingPage';
import { LANDING_PAGE_HEADER_PATHS } from '~/base/constants/headerConstants';

import FlagNameEnum from '~/base/enums/flagNameEnum';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import FlagCheckedChildren from '../../FlagCheckedChildren';
import HeaderAdmin from '../HeaderAdmin';
import HeaderNoticeBar from '../HeaderNoticeBar';

function Header() {
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const { pathname } = useLocation();
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);

  const useSwapBar = loggedInUser?.loggedInSongtrustUser?.isGhostedAdmin;
  const { t } = useTranslation(['translation']);

  const hasPaymentAndTaxInformation =
    loggedInUser?.loggedInSongtrustUser?.paymentAndTaxInformation;

  const shouldDisplayTaxBanner = !(
    !loggedInUser?.loggedInSongtrustUser ||
    LANDING_PAGE_HEADER_PATHS.includes(pathname) ||
    // Account type does not require tax and payment information
    (hasPaymentAndTaxInformation &&
      !hasPaymentAndTaxInformation.doesAccountTypeRequireTaxAndPaymentInformation) ||
    // Account type requires tax and payment information but user has not provided it
    (hasPaymentAndTaxInformation &&
      hasPaymentAndTaxInformation.hasPaymentMethod &&
      hasPaymentAndTaxInformation.hasTaxInformation &&
      hasPaymentAndTaxInformation.hasValidAccount)
  );

  return (
    <>
      {useSwapBar && sessionToken && <HeaderAdmin />}
      <header data-testid="header" style={{ backgroundColor: '#000' }}>
        <Container data-testid="header-container">
          {LANDING_PAGE_HEADER_PATHS.includes(pathname) && (
            <HeaderLandingPage />
          )}
          {!LANDING_PAGE_HEADER_PATHS.includes(pathname) && !sessionToken && (
            <HeaderExternal />
          )}
          {!LANDING_PAGE_HEADER_PATHS.includes(pathname) && sessionToken && (
            <HeaderInternal />
          )}
        </Container>
      </header>
      <FlagCheckedChildren
        name={FlagNameEnum.SHOW_NOTICE_BANNER}
        type={FlagTypeEnum.FLAG}
        // eslint-disable-next-line react/jsx-no-useless-fragment
        alternative={<></>}
      >
        <HeaderNoticeBar
          color="#E0E0E0"
          message={t('notice.notice-text')}
          hasButton
          buttonLabel={t('notice.notice-button')}
          buttonLink={t('notice.notice-link')}
        />
      </FlagCheckedChildren>
      {shouldDisplayTaxBanner && (
        <HeaderNoticeBar
          color="#F2F2F2"
          message={t('tax-banner.text')}
          hasButton
          buttonLabel={t('tax-banner.button')}
          buttonLink={t('tax-banner.link')}
          target="_self"
        />
      )}
    </>
  );
}

export default Header;
