import { Button, Grid, Typography } from '@mui/material';

/**
 * Props for the HeaderNoticeBar component
 * @typedef {Object} HeaderNoticeBarProps
 * @property {string} color - Background color for the notice bar
 * @property {string} message - Message to display in the notice bar
 * @property {boolean} hasButton - Whether to show a button in the notice bar
 * @property {string} buttonLabel - Label for the button
 * @property {string} buttonLink - Link for the button action
 * @property {string} [target] - Target for the button link, default is '_blank'
 */
interface HeaderNoticeBarProps {
  color: string;
  message: string;
  hasButton: boolean;
  buttonLabel: string;
  buttonLink: string;
  target?: string;
}

/**
 * HeaderNoticeBar component to display a notice bar with an optional button
 * @param {HeaderNoticeBarProps} props - Props for the component
 * @returns {JSX.Element} HeaderNoticeBar component
 */
function HeaderNoticeBar({
  color,
  message,
  hasButton,
  buttonLabel,
  buttonLink,
  target = '_blank',
}: HeaderNoticeBarProps) {
  const clickActionHandler = () => {
    window.open(buttonLink, target);
  };

  return (
    <div data-testid="header-notice-bar">
      <Grid
        container
        sx={{
          padding: '.5rem',
          background: color,
        }}
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Grid
            container
            spacing={2}
            alignContent="center"
            alignItems="center"
            justifyContent="space-around"
          >
            <Grid item>
              <Typography
                variant="subtitle2"
                component="span"
                sx={{
                  display: { xs: 'block', md: 'inline' },
                  marginRight: '2rem',
                  fontWeight: 'bold',
                }}
              >
                {message}
              </Typography>
              {hasButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    display: { xs: 'block', md: 'inline' },
                    margin: 'auto',
                    background: '#fff',
                    lineHeight: '1rem',
                  }}
                  onClick={clickActionHandler}
                >
                  {buttonLabel}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default HeaderNoticeBar;
