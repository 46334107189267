import { Box, Container, Grid, Typography } from '@mui/material';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import ChangePasswordSection from '../ChangePasswordSection/ChangePasswordSection';

function ChangePasswordPage({ t }: Translator) {
  usePageTitle(t('sections.change-password.title'));

  return (
    <div data-testid="change-password-page">
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                pt: { md: '2rem' },
              }}
            >
              <Typography
                data-testid="change-password-page-title"
                variant="h1"
                component="h1"
              >
                {t('sections.change-password.title')}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box
              sx={{
                pl: '1rem',
              }}
            >
              <ChangePasswordSection t={t} dashboardRedirect={false} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ChangePasswordPage;
