import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { kebabCase } from 'lodash';
import { SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * Interface for radio option.
 */
interface RadioOption {
  choiceId: string;
  choiceLabel: string;
  disabled?: boolean;
}

/**
 * Props for the FormRadio component.
 */
interface FormRadioProps {
  id: string;
  label?: string;
  name: string;
  sx?: SxProps<Theme>;
  required?: boolean;
  options: RadioOption[];
  columns: number;
  chips?: boolean;
  onOptionSelected?: (event: SyntheticEvent, value: string) => void;
  autofocus?: boolean;
}

/**
 * FormRadio is a component that renders a group of radio buttons.
 *
 * @param {FormRadioProps} props - The props for the component.
 * @param {string} props.id - The ID for the form control.
 * @param {string} props.name - The name of the radio group.
 * @param {string} [props.label] - An optional label for the radio group.
 * @param {SxProps<Theme>} [props.sx] - Optional styles for the form control.
 * @param {boolean} [props.required=false] - Whether the radio group is required.
 * @param {RadioOption[]} props.options - The options for the radio buttons.
 * @param {number} props.columns - The number of columns for the radio buttons.
 * @param {boolean} [props.chips=false] - Whether to display the options as chips.
 * @param {(event: SyntheticEvent, value: string) => void} [props.onOptionSelected] - Optional function to call when an option is selected.
 * @param {boolean} [props.autofocus=false] - Whether the first option should be auto-focused.
 */
function FormRadio({
  id,
  name,
  label,
  sx = {},
  required = false,
  options,
  columns,
  chips = false,
  onOptionSelected,
  autofocus = false,
}: FormRadioProps) {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const labelId = `${id}-label`;
  const error = name in errors ? errors[name] : '';
  const maxNumberOfColumns = 12 / columns;
  const labelComponent = label ? (
    <FormLabel data-testid={labelId} id={labelId} sx={{ fontSize: '0.75rem' }}>
      {label}
    </FormLabel>
  ) : null;

  return (
    <FormControl id={id} sx={sx} error={!!error}>
      {labelComponent}
      <Controller
        rules={{ required }}
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, ref, value } }) => (
          <RadioGroup
            onChange={(event, val) => {
              onChange(event);
              if (onOptionSelected) {
                onOptionSelected(event, val);
              }
            }}
            onBlur={onBlur}
            ref={ref}
            aria-labelledby={labelId}
            data-testid={kebabCase(id)}
          >
            <Grid container rowGap={chips ? 2 : 1} columnSpacing={3}>
              {options.map(({ choiceId, choiceLabel, disabled }, index) => (
                <Grid
                  item
                  md={maxNumberOfColumns}
                  sm={maxNumberOfColumns * 2}
                  xs={maxNumberOfColumns * 2}
                  key={choiceId}
                >
                  <FormControlLabel
                    value={choiceId}
                    control={
                      <Radio
                        name={name}
                        autoFocus={autofocus && index === 0}
                        checked={`${choiceId}` === `${value}`}
                        sx={{ display: chips ? 'none' : 'initial' }}
                      />
                    }
                    disabled={disabled}
                    label={
                      chips ? (
                        <Chip
                          sx={{
                            fontWeight: 'bold',
                            minWidth: '6.5rem',
                          }}
                          label={choiceLabel}
                          clickable
                          color={value === choiceId ? 'secondary' : 'default'}
                          onKeyUp={(e) => {
                            if (e.key === ' ') {
                              const parentLabel =
                                document?.activeElement?.closest('label');

                              if (parentLabel) {
                                const radioInput: HTMLInputElement | null =
                                  parentLabel.querySelector(
                                    "input[type='radio']",
                                  );

                                const nextValue = radioInput?.value as string;
                                if (nextValue) {
                                  setValue(name, nextValue);
                                  if (onOptionSelected) {
                                    onOptionSelected(e, nextValue);
                                  }
                                }
                              }
                            }
                          }}
                        />
                      ) : (
                        choiceLabel
                      )
                    }
                    sx={{
                      minWidth: '20%',
                      '& .MuiTypography-root': {
                        fontSize: '.875rem',
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}

export default FormRadio;
