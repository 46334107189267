import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import { Key, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import {
  SongtrustUserType,
  SongwriterType,
  useCurrentUserQuery,
  useSongSongwritersLazyQuery,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import SelectedSongwriter from './SelectedSongwriter';
import {
  alternateSongwriter,
  findSameName,
  nameArray,
  renderLabel,
} from './utils';

export default function Cowriters({ t }: Translator) {
  const { data: currentUser } = useCurrentUserQuery();
  const [fetchSongwriters, { data, loading }] = useSongSongwritersLazyQuery();
  const { control, watch } = useFormContext();
  const { append, remove } = useFieldArray({
    name: 'cowriters',
    control,
  });
  const songwriter = watch('songwriter');
  const cowriters = watch('cowriters');
  const overclaiming = watch('overclaiming');
  const [inputSongwriterValue, setInputSongwriterValue] = useState(
    songwriter?.inputValue,
  );

  const fetchWithDebounce = useMemo(
    () =>
      debounce((input: string) => {
        fetchSongwriters({
          variables: {
            searchFor: input,
          },
        });
      }, 300),
    [],
  );

  useEffect(() => {
    if (inputSongwriterValue?.length > 1)
      fetchWithDebounce(inputSongwriterValue);
  }, [inputSongwriterValue, fetch]);

  return (
    <Grid
      data-testid="cowriters-container"
      container
      item
      xs={12}
      md={6.35}
      gap={3}
    >
      <FormAutoComplete
        id="cowriter-input"
        testid="cowriter-container"
        sx={{
          width: '100%',
        }}
        label={t(`form.labels.cowriter`)}
        name="c"
        placeholder={t('form.placeholders.cowriter')}
        options={
          data?.songwriters?.edges
            ?.map((edge, _, edges) => {
              const alteration = renderLabel(
                alternateSongwriter(
                  edge?.node as SongwriterType,
                  edges
                    .filter(
                      (e) =>
                        !nameArray(e?.node as SongwriterType).every(
                          (v, i) =>
                            v === nameArray(edge?.node as SongwriterType)[i],
                        ),
                    )
                    .some((e) =>
                      findSameName(
                        nameArray(e?.node as SongwriterType),
                        nameArray(edge?.node as SongwriterType),
                      ),
                    ),
                  currentUser?.loggedInSongtrustUser as SongtrustUserType,
                  t,
                ),
                true,
              );
              return {
                choiceId: edge?.node?.id ?? '',
                choiceLabel:
                  `${[
                    ...nameArray(edge?.node as SongwriterType),
                    alteration,
                  ].reduce((s: string, c) => s.concat(renderLabel(c)), '')}` ??
                  '',
              };
            })
            .filter((c) => {
              const s = [songwriter]
                .concat(cowriters)
                .map((o) => o.songwriterId);
              return !s.includes(c.choiceId);
            }) ?? []
        }
        autocompleteSX={{
          '.MuiAutocomplete-popupIndicator': {
            transform: 'none',
          },
          width: '100%',
        }}
        popupIcon={<SearchIcon fontSize="small" />}
        forcePopupIcon
        onOptionSelected={(value, label) => {
          const outside = new RegExp(t('form.outside', 'i'));
          append({
            edit: true,
            isOutsideSongwriter: outside.test(label),
            saved: false,
            songwriterId: value,
            percent: null,
            label,
          });
        }}
        inputProps={{ 'data-testid': `cowriter-input` }}
        onInputChange={(event: React.SyntheticEvent, value: string) => {
          setInputSongwriterValue(value);
        }}
        displayRequired={overclaiming === 'yes'}
        freeSolo
        loading={loading}
      />
      <Grid container gap={4}>
        {cowriters.map(
          (c: { songwriterId: Key | null | undefined }, index: number) => (
            <SelectedSongwriter
              i={index}
              key={c.songwriterId}
              t={t}
              remove={remove}
            />
          ),
        )}
      </Grid>
    </Grid>
  );
}
