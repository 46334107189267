import {
  Box,
  Checkbox,
  Grid,
  Link,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { Dispatch, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import apraPdf from '~/assets/pdf/songtrust_tos_addendum_apra.pdf';
import FormSelect from '~/base/components/FormSelect';
import { Translator } from '~/types/Translator';
import { usePerformingRightsOrganizationsQuery } from '~/types/generated/graphql';
import AddPROLocationFilter from '../AddPROLocationFilter/AddPROLocationFilter';

interface AddPROSelectionProps extends Translator {
  setDisableContinueButton: Dispatch<React.SetStateAction<boolean>>;
}

/**
 * AddPROSelection component allows users to select a Performing Rights Organization (PRO)
 * and accepts additional terms based on their selection.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.t - Translation function from i18next.
 */
function AddPROSelection({
  t,
  setDisableContinueButton,
}: AddPROSelectionProps) {
  // Constants for PROs
  const APRA_PROS = ['APRA'];
  const DIRECT_PROS = [
    'ALBAUTOR',
    'ONDA',
    'SDADV',
    'UNAC-SA',
    'SADAIC',
    'ARMAUTHOR NGO',
    'AAS',
    'NCIP',
    'IMPF',
    'BSCAP',
    'BUBEDRA',
    'SOBODAYCOM',
    'AMUS',
    'COSBOTS',
    'AMAR SOMBRÁS',
    'ASSIM',
    'ECAD',
    'SBACEM',
    'SICAM',
    'SOCINPRO',
    'UBC',
    'BBDA',
    'CMC',
    'SCM-COOPERATIVA',
    'SCD',
    'MCSC',
    'ACINPRO',
    'SAYCO',
    'BCDA',
    'ACAM',
    'ACDAM',
    'SGACEDOM',
    'SAYCE',
    'SACERAU',
    'SACIM',
    'EAU',
    'KOPIOSTO',
    'SACENC',
    'GCA',
    'GHAMRO',
    'EDEM',
    'ORFIUM GREECE',
    'AEI-GUATEMALA',
    'BGDA',
    'UFFICIO GIURIDICO',
    'AACIMH',
    'CASH',
    'STEF',
    'SIAE',
    'BURIDA',
    'NEXTONE',
    'ABYROY',
    'KazAK',
    'MCSK',
    'KOMCA',
    'KOSCAP',
    'Kyrgyzpatent',
    'LATGA',
    'SACEM LUXEMBOURG',
    'MACA',
    'ZAMP Macedonia',
    'OMDA',
    'COSOMA',
    'MACP',
    'BUMDA',
    'MASA',
    'ANCO',
    'MOSCAP',
    'PAM CG',
    'BMDA',
    'SOMAS',
    'NASCAM',
    'MRCSN',
    'NICAUTOR',
    'BNDA',
    'COSON',
    'MCSN',
    'SPAC',
    'APA',
    'APDAYC',
    'FILSCAP',
    'RAO',
    'RUR',
    'RSAU',
    'SODAV',
    'SOKOJ',
    'SACS',
    'SINGCAPS',
    'SOZA',
    'SAZAS',
    'EKKI',
    'UNISON',
    'SASUR',
    'MÜST',
    'COSOTA',
    'COSOZA',
    'MCT',
    'BUTODRA',
    'ACCS',
    'COTT',
    'OTDAV',
    'MESAM',
    'UPRS',
    'NGO-UACRR',
    'ESMAA',
    'SOUNDREEF',
    'AMRA',
    'GMR',
    'HEXACORP',
    'AGADU',
    'SACVEN',
    'ZAMCOPS',
    'ZIMURA',
  ];
  const DONT_KNOW_VALUE = 'UNKNOWN';

  // State variables
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [showAPRACheckbox, setShowAPRACheckbox] = useState<boolean>(false);
  const [showDirectCheckbox, setShowDirectCheckbox] = useState<boolean>(false);
  const [showLocationPROFilter, setShowLocationPROFilter] =
    useState<boolean>(false);

  // Form context from react-hook-form
  const {
    setValue,
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  // Fetch PRO data using GraphQL query
  const { data: proData } = usePerformingRightsOrganizationsQuery();

  // State for sorted PROs
  const [sortedPROs, setSortedPROs] = useState<
    { choiceId: string; choiceLabel: string }[]
  >([]);

  /**
   * Effect to sort PROs on data fetch.
   * This prepares the PRO options for selection.
   */
  useEffect(() => {
    const proSorted =
      proData?.performingRightsOrganizations?.edges?.map((edge) => ({
        choiceId: edge?.node?.id || '',
        choiceLabel: edge?.node?.name || '',
      })) || [];

    const frontLoadArr = ['PRS', 'SOCAN', 'SESAC', 'BMI', 'ASCAP', 'UNKNOWN'];

    // Remove front-loaded PROs from the sorted list
    _.remove(proSorted, (x) => frontLoadArr.includes(x.choiceId));

    // Add front-loaded PROs to the beginning of the sorted list
    frontLoadArr.forEach((x) => {
      proSorted.unshift({
        choiceId: x,
        choiceLabel: x === 'UNKNOWN' ? "DON'T KNOW" : x,
      });
    });

    setSortedPROs(proSorted);
  }, [proData]);

  /**
   * Handle change for the selected PRO.
   * This updates the form state and manages visibility of checkboxes.
   *
   * @param {SelectChangeEvent} event - The change event from the select input.
   */
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    // Show location filter for 'UNKNOWN' selection
    setShowLocationPROFilter(value === DONT_KNOW_VALUE);
    if (value !== DONT_KNOW_VALUE) {
      setValue('pro', value);
      setDisableContinueButton(false);
    } else {
      setValue('pro', undefined);
      setDisableContinueButton(true);
    }

    const isApraPro = APRA_PROS.includes(value);
    const isDirectPro = DIRECT_PROS.includes(value);

    // Manage checkbox visibility and registration
    if (isApraPro || isDirectPro) {
      setShowAPRACheckbox(isApraPro);
      setShowDirectCheckbox(isDirectPro);
      register('accept-extra-terms', { required: true });
      setValue('accept-extra-terms', false);
    } else {
      setShowAPRACheckbox(false);
      setShowDirectCheckbox(false);
      unregister('accept-extra-terms');
    }
  };

  return (
    <div data-testid="add-songwriter-pro-selection">
      <Grid
        container
        spacing={2}
        sx={{ pt: 2, pb: 2, flexDirection: { xs: 'row' } }}
      >
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={700}>
            {t('page.add-songwriter.form.pro.what-pro')}
          </Typography>
        </Grid>

        {/* PRO Selection */}
        <Grid item xs={12}>
          <FormSelect
            id="pro-selection"
            data-testid="pro-selection"
            name="pro"
            label={t('page.add-songwriter.form.pro.select-pro')}
            options={sortedPROs}
            onOptionSelected={handleChange}
            required
          />
        </Grid>

        {/* Conditional rendering of checkboxes and messages */}
        {(showAPRACheckbox || showDirectCheckbox) && (
          <>
            {showDirectCheckbox && (
              <>
                <Grid item xs={8}>
                  <p>
                    {t('page.add-songwriter.form.pro.direct-pro-message-1')}
                  </p>
                  <p>
                    {t('page.add-songwriter.form.pro.direct-pro-message-2')}{' '}
                    <Link
                      href={t('page.add-songwriter.form.pro.direct-link')}
                      target="_blank"
                    >
                      {t('page.add-songwriter.form.pro.here')}
                    </Link>
                    .
                  </p>
                </Grid>
                <Grid item xs={4} />
              </>
            )}
            <Grid item xs={8}>
              <Box
                sx={{
                  border: errors['accept-extra-terms']
                    ? '1px solid #f00'
                    : '1px solid #ccc',
                }}
              >
                <Checkbox
                  name="accept-extra-terms"
                  data-testid="accept-extra-terms"
                  checked={termsAccepted}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setTermsAccepted(checked);
                    setValue('accept-extra-terms', checked);
                  }}
                />
                {showDirectCheckbox && (
                  <span>
                    {t('page.add-songwriter.form.pro.agree')}{' '}
                    {t('page.add-songwriter.form.pro.above')}
                  </span>
                )}
                {showAPRACheckbox && (
                  <span>
                    {t('page.add-songwriter.form.pro.agree')}{' '}
                    <Link href={apraPdf} target="_blank">
                      {t('page.add-songwriter.form.pro.apra-terms')}
                    </Link>
                  </span>
                )}
              </Box>
            </Grid>
            <Grid item xs={4} />
          </>
        )}

        {/* Location Filter */}
        {showLocationPROFilter && (
          <Grid item xs={12}>
            <AddPROLocationFilter
              t={t}
              setDisableContinueButton={setDisableContinueButton}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default AddPROSelection;
