import { Box, Grid, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Translator } from '~/types/Translator';
import { SongwriterType } from '~/types/generated/graphql';

interface SongwriterDetailInfoProps extends Translator {
  songwriter: SongwriterType;
}
function SongwriterDetailInfo({ t, songwriter }: SongwriterDetailInfoProps) {
  const [lodVerified, setLodVerified] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (songwriter && songwriter.currentStatus && songwriter.currentStatus[0]) {
      // Error?
      setHasError(
        (songwriter?.currentStatus[0].indexOf('error') > 0) as boolean,
      );

      // LOD Verified?
      setLodVerified(
        (songwriter?.currentStatus[0].indexOf('lod_verified') > 0) as boolean,
      );
    }
  }, [songwriter]);

  return (
    songwriter && (
      <div data-testid="songwriter-detail-info">
        <Grid container sx={{ backgroundColor: '#E0E0E0', p: '1rem' }}>
          <Grid item xs={6}>
            <Typography variant="h2" component="h2">
              {`${songwriter.firstName} ${songwriter.middleName} ${songwriter.lastName}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {songwriter.ipi && (
              <Box>
                {t('page.songwriter-details.ipi')}: <b>{songwriter.ipi}</b>
              </Box>
            )}
            {songwriter.email && (
              <Box>
                {t('page.songwriter-details.email')}: <b>{songwriter.email}</b>
              </Box>
            )}
            {songwriter.created && (
              <Box>
                {t('page.songwriter-details.date-joined')}:{' '}
                <b>{dayjs(songwriter.created).format('MMM DD, YYYY')}</b>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          data-testid="songwriter-details-affiliation"
          sx={{ backgroundColor: '#F2F2F2', p: '1rem' }}
        >
          <Grid item xs={6}>
            {songwriter.pro && (
              <Box>
                {t('page.songwriter-details.pro-affiliation')}:
                <Typography variant="h2" component="h3">
                  {songwriter.pro?.name}
                </Typography>
              </Box>
            )}
            {songwriter.publishingCompany && (
              <Box>
                {t('page.songwriter-details.publisher')}:
                <Typography variant="h2" component="h3">
                  {songwriter.publishingCompany?.name}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            {!hasError && lodVerified && (
              <Box>
                <Link
                  href={`${
                    import.meta.env.VITE_LEGACY_APP_AUTH_REDIRECT
                  }/pdfs/letter-of-direction/${songwriter.id}`}
                  target="_blank"
                >
                  {t('page.songwriter-details.letter-of-direction')}
                </Link>
              </Box>
            )}

            {!songwriter.hasApplication && !songwriter.hasLod && (
              <Box>
                <Typography variant="body1" component="div">
                  {t('page.songwriter-details.registration-status')}
                </Typography>

                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  {songwriter?.currentStatus && songwriter?.currentStatus[1]}
                </Typography>
              </Box>
            )}

            {hasError && (songwriter.hasApplication || songwriter.hasLod) && (
              <Box>
                {t('page.songwriter-details.error')}:
                <Typography
                  variant="h5"
                  sx={{ color: '#D5164B', textDecoration: 'underline' }}
                >
                  {t('page.songwriter-details.something-wrong')}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    )
  );
}

export default SongwriterDetailInfo;
