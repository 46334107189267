import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useFormContext, UseFieldArrayRemove } from 'react-hook-form';

export default function SelectedSongwriterSaved({
  i,
  remove,
}: {
  i: number;
  remove: UseFieldArrayRemove;
}) {
  const { setValue, getValues } = useFormContext();
  const cowriter = getValues(`cowriters.${i}`);

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <IconButton
        onClick={() => {
          setValue(`cowriters.${i}.edit`, true);
        }}
      >
        <EditIcon />
      </IconButton>
      <Typography>{cowriter?.label}</Typography>
      <Typography>{cowriter?.percent}%</Typography>
      <IconButton
        onClick={() => {
          remove(i);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Grid>
  );
}
