/* eslint-disable react/require-default-props */
import { Grid, Link, Typography } from '@mui/material';
import { t } from 'i18next';

type SubHeaderProps = {
  title: string | undefined;
  subtitle?: string | undefined;
  breadcrumb?: string | undefined;
  breadcrumbLink?: string | undefined;
};

function SubHeader({
  title = '',
  subtitle = undefined,
  breadcrumb = undefined,
  breadcrumbLink = undefined,
}: SubHeaderProps) {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Typography variant="body1" component="p" sx={{ pt: '1rem' }}>
          <Link
            component="a"
            data-testid="song-detail-back-link"
            href={breadcrumbLink}
          >
            {breadcrumb}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          data-testid="song-detail-page-title"
          variant="h1"
          component="h1"
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            data-testid="song-detail-page-subtitle"
            variant="body1"
            component="p"
          >
            {t('page.song-details.subtitle')}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default SubHeader;
