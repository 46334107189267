import { ApolloError, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { currentSessionTokenVar } from '~/cache';
import useLoginRefresh from '~/login/hooks/useLoginRefresh';
import { LogoutUserMutation } from '~/types/generated/graphql';

function useLoginRefreshInterval(
  delay: number,
  callbackError: (error: ApolloError) => void,
  callbackSuccess?: (data: LogoutUserMutation) => void,
) {
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const [loginRefresh] = useLoginRefresh(callbackError, callbackSuccess);

  useEffect(() => {
    const timer = setInterval(() => {
      if (sessionToken) {
        loginRefresh();
      }
    }, delay);
    return () => clearInterval(timer);
  }, [sessionToken, callbackSuccess, callbackError, delay]);
}

export default useLoginRefreshInterval;
