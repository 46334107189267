import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import {
  SongInput,
  SongtrustUserType,
  useCurrentUserQuery,
  useSongCreateMutation,
} from '~/types/generated/graphql';
import ISWC from '../ISWC';
import Lyrics from '../Lyrics/Lyrics';
import SongTitle from '../SongTitle/SongTitle';
import Songwriters from '../Songwriters/Songwriters';
import { getSongwriters } from '../Songwriters/utils';
import PreSubmissionModal from './PreSubmissionModal';
import SongAddedModal from './SongAddedModal';

function AddSongPage({ t }: Translator) {
  const { state: copySongwritersState } = useLocation();
  const { data: currentUser } = useCurrentUserQuery();
  const songwriters = copySongwritersState
    ? getSongwriters(
        copySongwritersState.song,
        currentUser?.loggedInSongtrustUser as SongtrustUserType,
        t,
      )
    : [];
  usePageTitle(t('page.titles.addSong'));

  type songValueKeys = 'title' | 'iswc' | 'lyrics.language';

  const defaultSongValues = {
    title: '',
    alternateTitles: [],
    publicDomain: false,
    songwriter: songwriters.length
      ? songwriters[0]
      : {
          songwriterId: 0,
          percent: '',
          isOutsideSongwriter: false,
        },
    cowriters: songwriters.length ? songwriters.slice(1) : [],
    iswc: '',
    overclaiming: null as 'yes' | 'no' | null,
    overclaimingAgreement: false,
    copyrightInfringement: false,
    copyrightInfringementAgreement: false,
    language: 'en',
    fullLyrics: '',
    chorusLyrics: '',
    lyrics: {
      language: 'en',
    },
  };

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: defaultSongValues,
    shouldUseNativeValidation: true,
  });

  const [preSubmission, setPreSubmission] = useState({
    open: false,
    agreed: false,
  });
  const [songAddedOpen, setSongAddedOpen] = useState(false);

  const [createSong, { loading, data }] = useSongCreateMutation({
    // Convert errors state for fields with errors
    onCompleted(d) {
      if (d.songCreate?.errors) {
        d.songCreate?.errors?.forEach((e) => {
          const key = e?.path.slice(1).shift();
          formMethods.setError(key as songValueKeys, {
            message: e?.error,
            type: 'string',
          });
        });
        setPreSubmission({
          open: false,
          agreed: false,
        });
      } else {
        setSongAddedOpen(true);
      }
    },
  });

  const { watch } = formMethods;
  const cowritersNeeded =
    watch('overclaiming') === 'yes' && watch('cowriters').length < 1;

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <form
      data-testid="add-song-page"
      onSubmit={formMethods.handleSubmit(() => {
        setPreSubmission((state) => ({ ...state, open: true }));
      })}
    >
      <SongAddedModal
        id={data?.songCreate?.song?.id}
        t={t}
        open={songAddedOpen}
        setOpen={setSongAddedOpen}
      />
      <FormProvider {...formMethods}>
        <Container>
          <Grid container item xs={12}>
            <Box
              sx={{
                pt: { md: '2rem' },
                pb: '3rem',
              }}
            >
              <Typography variant="h1">{t('page.titles.addSong')}</Typography>
            </Box>
            <SongTitle t={t} />
            <Songwriters t={t} />
            <ISWC t={t} />
            <Lyrics t={t} />
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              sx={{ marginTop: '2rem' }}
            >
              <Paper
                sx={{
                  width: xs ? '100%' : '30%',
                  padding: '2rem',
                  backgroundColor: '#FF8100',
                }}
              >
                <Typography variant="h3">
                  {t('form.titles.beforeSubmission')}
                </Typography>
                <Paper elevation={0} sx={{ padding: '1rem' }}>
                  <Typography>{t('form.copy.beforeSubmission')}</Typography>
                </Paper>
              </Paper>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{ justifyContent: 'space-between', marginTop: '2rem' }}
            >
              <Button
                variant="outlined"
                sx={{
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                }}
                onClick={() => {
                  formMethods.reset();
                }}
              >
                {t('form.buttons.cancel')}
              </Button>
              <Button
                data-testid="song-form-submit"
                type="submit"
                variant="contained"
                disabled={
                  !formMethods.formState.isValid || cowritersNeeded || loading
                }
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'text.primary',
                  '&:hover': {
                    color: 'white',
                  },
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                }}
              >
                {loading ? t('form.loading') : t('form.buttons.submit')}
              </Button>
            </Grid>
          </Grid>
          <PreSubmissionModal
            t={t}
            preSubmission={preSubmission}
            setPreSubmission={setPreSubmission}
            postSong={formMethods.handleSubmit((value) => {
              const mainSongwriterFormatted = {
                songwriterId: parseInt(`${value.songwriter.songwriterId}`, 10),
                percent: parseFloat(value.songwriter.percent),
                isOutsideSongwriter: false,
              };
              const ownerships = [mainSongwriterFormatted].concat(
                value.cowriters.map((cowriter) => ({
                  songwriterId: parseInt(`${cowriter.songwriterId}`, 10),
                  percent: parseFloat(cowriter.percent),
                  isOutsideSongwriter: cowriter.isOutsideSongwriter,
                })),
              );
              const song: SongInput = {
                chorusLyrics: value.chorusLyrics,
                fullLyrics: value.fullLyrics,
                language: value.language,
                title: value.title,
                alternateTitles: value.alternateTitles,
                publicDomain: value.publicDomain,
                iswc: value.iswc,
                ownerships,
              };
              createSong({ variables: { song } });
            })}
          />
        </Container>
      </FormProvider>
    </form>
  );
}

export default AddSongPage;
