/* eslint-disable react/jsx-no-useless-fragment */
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, Grid, Tooltip } from '@mui/material';
import { Translator } from '~/types/Translator';
import { SongwriterType } from '~/types/generated/graphql';

interface ApplicationStatusColumnProps extends Translator {
  songwriter: SongwriterType;
}

function ApplicationStatusColumn({
  songwriter,
  t,
}: ApplicationStatusColumnProps) {
  let statusText = '';
  let statusTooltip = '';
  let alertInstruction = '';

  const {
    id,
    currentStatus,
    manageable,
    hasApplication,
    hasLod,
    statusLog,
    pro,
  } = songwriter;

  const statusCode = currentStatus && (currentStatus[0] as string);
  let calculatedColor = '#F33126';

  // Error state.
  if (statusCode === '00--error' || statusCode === '01--error') {
    statusText = t(
      'page.songwriters.tabs.client.table.columns.writer-status.status.error',
    );
    const proOrLod = statusCode === '00--error' ? 'PRO' : 'LOD';
    if (manageable) {
      if (hasApplication || hasLod) {
        const url = 'https://www.songtrust.com/songtrust-contact-us';
        alertInstruction = ` <a href="${url}">${t(
          'page.songwriters.tabs.client.table.columns.writer-status.tooltips.client-services',
        )}</a>.`;
      } else {
        const url = `/songwriters/#edit/${proOrLod.toLowerCase()}-form/${id}`;
        alertInstruction = ` <a href="${url}">${t(
          'page.songwriters.tabs.client.table.columns.writer-status.tooltips.please-resubmit',
        )}</a>.`;
      }
    }
    statusTooltip = `${t(
      'page.songwriters.tabs.client.table.columns.writer-status.tooltips.something-wrong',
    )} ${proOrLod} ${t(
      'page.songwriters.tabs.client.table.columns.writer-status.tooltips.application',
    )}${alertInstruction}`;
  } else {
    // Other State.
    switch (statusLog?.latest) {
      case 'approved':
        calculatedColor = 'green';

        statusText = t(
          'page.songwriters.tabs.client.table.columns.writer-status.status.member',
        );

        if (hasApplication) {
          statusTooltip = `${t(
            'page.songwriters.tabs.client.table.columns.writer-status.tooltips.approved-by',
          )}${pro?.name}${t(
            'page.songwriters.tabs.client.table.columns.writer-status.tooltips.fully-register',
          )}`;
        } else {
          statusTooltip = t(
            'page.songwriters.tabs.client.table.columns.writer-status.tooltips.affiliation-confirmed',
          );
        }
        break;

      case 'sent':
        calculatedColor = 'blue';
        statusText = t(
          'page.songwriters.tabs.client.table.columns.writer-status.status.sent',
        );
        statusTooltip = `${t(
          'page.songwriters.tabs.client.table.columns.writer-status.tooltips.application-sent',
        )}${pro?.name}${t(
          'page.songwriters.tabs.client.table.columns.writer-status.tooltips.click-for-more',
        )}`;
        break;

      case 'processing':
      default:
        calculatedColor = '#B85D00';
        statusText = t(
          'page.songwriters.tabs.client.table.columns.writer-status.status.processing',
        );
        if (hasApplication) {
          statusTooltip = `${t(
            'page.songwriters.tabs.client.table.columns.writer-status.tooltips.currently-processing',
          )}${pro?.name}${t(
            'page.songwriters.tabs.client.table.columns.writer-status.tooltips.processing-to-accept',
          )}`;
        } else {
          statusTooltip = t(
            'page.songwriters.tabs.client.table.columns.writer-status.tooltips.reviewing-details',
          );
        }
        break;
    }
  }

  return (
    <Box data-testid="writer-status-column">
      <Grid container>
        <Grid item xs={12}>
          <Tooltip
            title={
              <Box
                sx={{ a: { color: '#D5164B' } }}
                dangerouslySetInnerHTML={{ __html: statusTooltip as string }}
              />
            }
          >
            <Grid
              container
              justifyItems="center"
              sx={{
                cursor: 'pointer',
                color: calculatedColor,
                fontWeight: 'bold',
              }}
            >
              <Grid item sx={{ pr: '.5rem' }}>
                {statusLog?.latest === 'sent' && <AccessTimeIcon />}
                {statusLog?.latest === 'processing' &&
                  statusCode !== '00--error' &&
                  statusCode !== '01--error' && <SyncIcon />}
                {(statusCode === '00--error' || statusCode === '01--error') && (
                  <ErrorOutlineIcon />
                )}
                {statusLog?.latest === 'approved' && <CheckCircleOutlineIcon />}
              </Grid>
              <Grid item>{statusText}</Grid>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ApplicationStatusColumn;
