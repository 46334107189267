/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from '~/base/components/FormInput';
import FormSelect from '~/base/components/FormSelect';
import FormTooltip from '~/base/components/FormTooltip';
import { Translator } from '~/types/Translator';
import { usePerformingRightsOrganizationsQuery } from '~/types/generated/graphql';

type AddSongtrustWriterPublishingCompanyProps = Translator;
function AddSongtrustWriterPublishingCompany({
  t,
}: AddSongtrustWriterPublishingCompanyProps) {
  const { data: proData } = usePerformingRightsOrganizationsQuery();

  const { setValue } = useFormContext();
  const [sortedPROs, setSortedPROs] = useState<
    {
      choiceId: string;
      choiceLabel: string;
    }[]
  >([]);

  useEffect(() => {
    setValue('has-publishingcompany', 'no');
  }, []);

  useEffect(() => {
    const proSorted =
      proData?.performingRightsOrganizations?.edges?.map((edge) => {
        return {
          choiceId: edge?.node?.id || '',
          choiceLabel: edge?.node?.name || '',
        };
      }) || [];

    // Remove front load array from the list
    const frontLoadArr = ['PRS', 'SOCAN', 'SESAC', 'BMI', 'ASCAP'];
    _.remove(proSorted, (x) => {
      return frontLoadArr.includes(x.choiceId);
    });

    // Remove UNKNOWN from the list
    _.remove(proSorted, (x) => {
      return x.choiceId === 'UNKNOWN';
    });

    // Add front load array to the start of the list.
    _.each(frontLoadArr, (x) => {
      if (x !== 'UNKNOWN') proSorted.unshift({ choiceId: x, choiceLabel: x });
      else proSorted.unshift({ choiceId: x, choiceLabel: "DON'T KNOW" });
    });

    setSortedPROs(proSorted);
  }, [proData]);

  const proButtonSx = {
    background: '#7E7E7E',
    color: '#fff',
    border: 'none',
    ':hover, &.selected': {
      border: 'none',
      background: '#B4012D',
    },
    textAlign: 'center',
    p: 1,
    pr: 2,
    pl: 2,
    cursor: 'pointer',
    width: { xs: '100%', md: '50%' },
  };

  const [hasPublishingCompany, setHasPublishingCompany] =
    useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    setValue('pro', event.target.value);
  };

  return (
    <Grid
      container
      spacing={2}
      data-testid="add-songtrust-writer-publishing-company"
      sx={{ pb: '2rem', pt: '2rem' }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component="h1"
          sx={{ borderBottom: '1px solid #000' }}
        >
          {t('page.add-songwriter.form.songwriter.publishing.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="p">
          {t('page.add-songwriter.form.songwriter.publishing.have-entity')}
          <Box sx={{ ml: '0.5rem' }} component="span">
            <FormTooltip
              tooltip={t(
                'page.add-songwriter.form.songwriter.publishing.not-sure',
              )}
            />
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
          className={hasPublishingCompany ? 'selected' : ''}
          data-testid="publishingcompany-yes"
          variant="outlined"
          onClick={() => {
            setValue('has-publishingcompany', 'yes');
            setHasPublishingCompany(true);
          }}
          sx={proButtonSx}
        >
          {t('page.add-songwriter.form.songwriter.publishing.yes')}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ pb: '2rem' }}>
        <Button
          className={!hasPublishingCompany ? 'selected' : ''}
          data-testid="publishingcompany-no"
          variant="outlined"
          onClick={() => {
            setValue('has-publishingcompany', 'no');
            setHasPublishingCompany(false);
          }}
          sx={proButtonSx}
        >
          {t('page.add-songwriter.form.songwriter.publishing.no')}
        </Button>
      </Grid>
      {hasPublishingCompany && (
        <>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormInput
                  id="publishingcompany-name"
                  data-testid="publishingcompany-name"
                  name="publishingcompany-name"
                  label={t(
                    'page.add-songwriter.form.songwriter.publishing.publishingcompany-name.label',
                  )}
                  required={{
                    value: true,
                    message: t(
                      'page.add-songwriter.form.songwriter.publishing.publishingcompany-name.required',
                    ),
                  }}
                  sx={{ width: '100%' }}
                  tooltip={t(
                    'page.add-songwriter.form.songwriter.publishing.publishingcompany-name.tooltip',
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  id="publishingcompany-pro-selection"
                  data-testid="publishingcompany-pro-selection"
                  name="publishingcompany-pro"
                  label={t(
                    'page.add-songwriter.form.songwriter.publishing.publishingcompany-pro.label',
                  )}
                  options={sortedPROs}
                  onOptionSelected={handleChange}
                  sx={{ width: '100%' }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  id="publishingcompany-ipi"
                  data-testid="publishingcompany-ipi"
                  name="publishingcompany-ipi"
                  placeholder={t(
                    'page.add-songwriter.form.songwriter.publishing.publishingcompany-ipi.placeholder',
                  )}
                  label={t(
                    'page.add-songwriter.form.songwriter.publishing.publishingcompany-ipi.label',
                  )}
                  required={{
                    value: true,
                    message: t(
                      'page.add-songwriter.form.songwriter.publishing.publishingcompany-ipi.required',
                    ),
                  }}
                  sx={{ width: '100%' }}
                  tooltip={t(
                    'page.add-songwriter.form.songwriter.publishing.publishingcompany-ipi.tooltip',
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} />
        </>
      )}
    </Grid>
  );
}

export default AddSongtrustWriterPublishingCompany;
