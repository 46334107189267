import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  PER_SONGWRITER_COST,
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from '~/signup/constants/signupConstants';
import { Translator } from '~/types/Translator';
import {
  VerifyDiscountCodeDocument,
  VerifyDiscountCodeQuery,
} from '~/types/generated/graphql';
import SignupDiscountDisplay from '../SignupDiscountDisplay/SignupDiscountDisplay';
import SignupDiscountLink from '../SignupDiscountLink/SignupDiscountLink';
import SignupDiscountLinkEntry from '../SignupDiscountLinkEntry/SignupDiscountLinkEntry';

interface SignupOrderSummaryProps extends Translator {
  setHasSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  idVerified: boolean;
  isLoading: boolean;
}

function SignupOrderSummary({
  t,
  setHasSubmitted,
  idVerified,
  isLoading,
}: SignupOrderSummaryProps) {
  const [searchParams] = useSearchParams();

  const queryDiscountCode = searchParams.get('discount_code')
    ? searchParams.get('discount_code')
    : searchParams.get('referral_code');

  const { setValue, reset } = useFormContext();

  const [invalidCode, setInvalidCode] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [songwriterCount, setSongwriterCount] = useState<number>(1);
  const [editSongwriterCount, setEditSongwriterCount] = useState<number>(1);

  const [totalPrice, setTotalPrice] = useState<number>(1 * PER_SONGWRITER_COST);
  const [totalDiscountPercent, setTotalDiscountPercent] = useState<number>(0);
  const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState<number>(
    1 * PER_SONGWRITER_COST,
  );

  const [discountMode, setDiscountMode] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>('');
  const [editDiscountCode, setEditDiscountCode] = useState<string>(
    queryDiscountCode !== null ? queryDiscountCode : '',
  );

  const [termsAccepted, setTermsAccepted] = useState(false);

  const updateFormValues = (songwriters: number, code: string) => {
    setValue('numberOfSongwriters', songwriters);
    setValue('discountCode', code);
    setValue('acceptedTermsOfUse', termsAccepted);
  };

  const updateTotalPrice = (discountPercent: number) => {
    const totalCost = editSongwriterCount * PER_SONGWRITER_COST;
    const totalDiscount = discountPercent * totalCost;
    const totalCostWithDiscount = totalCost - totalDiscount;

    setTotalPrice(totalCost);
    setTotalPriceWithDiscount(totalCostWithDiscount);
    updateFormValues(editSongwriterCount, editDiscountCode);
  };

  const afterCheckDiscountCodeComplete = (
    res: VerifyDiscountCodeQuery | undefined,
  ) => {
    if (res?.verifyDiscountCode?.isValid) {
      const valueFromRequest = res?.verifyDiscountCode?.discount;
      setTotalDiscountPercent(valueFromRequest);
      updateTotalPrice(valueFromRequest);
      setDiscountMode(!discountMode);
    } else {
      setInvalidCode(true);
    }
  };

  const [checkDiscountCode, { loading, error }] =
    useLazyQuery<VerifyDiscountCodeQuery>(VerifyDiscountCodeDocument, {
      fetchPolicy: 'no-cache',
      variables: { discountCode: editDiscountCode },
      onCompleted: (d) => {
        afterCheckDiscountCodeComplete(d);
      },
    });

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const handleDiscountCloseClick = () => {
    reset(
      { discountCode: '' },
      {
        keepErrors: false,
        keepIsValid: true,
        keepTouched: true,
      },
    );
    setInvalidCode(false);
    setDiscountMode(!discountMode);
    setEditDiscountCode('');
    setDiscountCode('');
  };

  const handleDiscountClick = () => {
    setDiscountMode(!discountMode);
  };

  const handleEditApplyClick = () => {
    setSongwriterCount(editSongwriterCount);
    updateTotalPrice(totalDiscountPercent);
    setEditMode(!editMode);
  };

  const handleDiscountApplyClick = () => {
    setInvalidCode(false);
    setDiscountCode(editDiscountCode);
    checkDiscountCode();
  };

  const handleRemoveDiscountClick = () => {
    setTotalDiscountPercent(0);

    updateTotalPrice(0);

    setDiscountCode('');
    setEditDiscountCode('');
    setDiscountMode(false);
  };

  const handleEditSongwriterCountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let newCount = 0;
    if (e.target.value !== '') newCount = parseInt(e.target.value, 10);
    setEditSongwriterCount(newCount);
  };

  const handleEditDiscountCodeChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditDiscountCode(e.target.value);
  };

  useEffect(() => {
    updateFormValues(songwriterCount, discountCode);
  }, [discountCode, songwriterCount, termsAccepted]);

  useEffect(() => {
    if (queryDiscountCode) {
      handleDiscountClick();
      setEditDiscountCode(queryDiscountCode);
      handleDiscountApplyClick();
    }
  }, [queryDiscountCode]);

  return (
    <Grid
      container
      rowSpacing={3}
      className="signup-page-order-summary"
      data-testid="signup-page-order-summary"
    >
      <Grid item xs={12}>
        <Typography variant="h1" component="h2">
          {t('form.order-summary')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            fontSize: '0.75rem',
            paddingBottom: '1rem',
            marginBottom: '.5rem',
            paddingLeft: '.5rem',
            paddingRight: '.5rem',
            borderBottom: '1px solid #E0E0E0',
          }}
        >
          {editMode && (
            <>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      padding: '0',
                      marginLeft: '-.75rem',
                      marginTop: '-.75rem',
                    },
                    shrink: false,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '.5rem',
                      width: '8rem',
                      height: '3.125rem',
                    },
                  }}
                  name="number-of-writers"
                  data-testid="number-of-writers"
                  value={editSongwriterCount}
                  onChange={handleEditSongwriterCountChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleEditApplyClick();
                    }
                  }}
                  label={t('form.number-of-writers')}
                  type="text"
                />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Button
                  data-testid="apply-writers-link"
                  variant="outlined"
                  color="success"
                  onClick={handleEditApplyClick}
                >
                  {t('form.apply')}
                </Button>
              </Grid>
            </>
          )}

          {!editMode && (
            <>
              <Grid item xs={3}>
                {songwriterCount} {songwriterCount > 1 ? `writer(s)` : `writer`}
              </Grid>
              <Grid item xs={3}>
                <Link
                  component={Button}
                  href="/#"
                  sx={{
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    handleEditClick();
                  }}
                  data-testid="edit-writers-link"
                  aria-label={t('form.edit-aria')}
                >
                  {t('form.edit')}
                </Link>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ textAlign: 'right' }}
                data-testid="total-songwriter-price-formatted"
              >
                <CurrencyFormat
                  value={totalPrice}
                  displayType="text"
                  prefix="$"
                  decimalSeparator="."
                  decimalScale={2}
                  thousandSeparator
                  fixedDecimalScale
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {!discountMode && !discountCode && (
        <SignupDiscountLink t={t} handleDiscountClick={handleDiscountClick} />
      )}

      {discountMode && (
        <SignupDiscountLinkEntry
          t={t}
          loading={loading}
          invalidCode={invalidCode}
          editDiscountCode={editDiscountCode}
          handleEditDiscountCodeChanges={handleEditDiscountCodeChanges}
          handleDiscountApplyClick={handleDiscountApplyClick}
          handleDiscountCloseClick={handleDiscountCloseClick}
          error={error}
        />
      )}

      {!discountMode && discountCode && (
        <SignupDiscountDisplay
          discountCode={discountCode}
          totalDiscountPercent={totalDiscountPercent}
          handleRemoveDiscountClick={handleRemoveDiscountClick}
          t={t}
        />
      )}

      <Grid item xs={12}>
        <Grid
          container
          sx={{
            fontSize: '0.75rem',
            paddingBottom: '1rem',
            paddingLeft: '.5rem',
            paddingRight: '.5rem',
            borderBottom: '1px solid #E0E0E0',
          }}
        >
          <Grid item xs={3}>
            {t('form.total')}
          </Grid>
          <Grid item xs={9} sx={{ textAlign: 'right' }}>
            <Box data-testid="total-cart-price-formatted">
              <CurrencyFormat
                value={totalPriceWithDiscount}
                displayType="text"
                prefix="$"
                decimalSeparator="."
                decimalScale={2}
                thousandSeparator
                fixedDecimalScale
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Checkbox
          name="accept-terms"
          data-testid="tos-agree-checkbox"
          checked={termsAccepted}
          onChange={(e) => {
            setTermsAccepted(e.target.checked);
          }}
        />
      </Grid>
      <Grid item xs={11}>
        <Box
          sx={{
            fontSize: '0.875rem',
            paddingLeft: '1rem',
          }}
        >
          {t('form.fields.tos-agree.label')}{' '}
          <Link
            href={TERMS_OF_SERVICE_LINK}
            target="_blank"
            data-testid="tos-signup"
          >
            {t('form.fields.tos-agree.terms')}
          </Link>{' '}
          {t('form.fields.tos-agree.label-2')}{' '}
          <Link
            href={PRIVACY_POLICY_LINK}
            target="_blank"
            data-testid="privacy-policy-signup"
          >
            {t('form.fields.tos-agree.privacy')}
          </Link>{' '}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {!isLoading && (
          <Button
            id="submit-payment-button"
            data-testid="submit-payment-button"
            variant="contained"
            color="secondary"
            type="submit"
            sx={{
              width: '100%',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
            disabled={!termsAccepted || !idVerified}
            onClick={() => {
              setHasSubmitted(true);
            }}
          >
            {t('form.submit-payment')}
          </Button>
        )}
        {isLoading && <LoadingIndicator size={50} />}
      </Grid>
    </Grid>
  );
}

export default SignupOrderSummary;
