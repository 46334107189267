import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import {
  FieldValues,
  FormProvider,
  UseFieldArrayAppend,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import FormInput from '~/base/components/FormInput';
import FormSelect from '~/base/components/FormSelect';
import { Translator } from '~/types/Translator';
import {
  OutsideSongwriterInput,
  useCreateOutsideSongwriterMutation,
  usePerformingRightsOrganizationsQuery,
} from '~/types/generated/graphql';

interface AddOutsideSongwriterProps extends Translator {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addCowriter: UseFieldArrayAppend<FieldValues, 'cowriters'>;
}

export default function AddOutsideSongwriter({
  t,
  open,
  setOpen,
  addCowriter,
}: AddOutsideSongwriterProps) {
  const handleClose = () => {
    setOpen(false);
  };
  const defaultValues: {
    input: OutsideSongwriterInput;
  } = {
    input: {
      email: '',
      honorific: '',
      firstName: '',
      middleName: '',
      lastName: '',
      alternateNames: [],
      ipi: '',
      songwriterType: 'outside',
      lodTosAccepted: true,
      pro: { name: '' },
      signature: '',
      birthdate: undefined,
      songwriterYoutubeClaims: true,
      publishingCompany: undefined,
    },
  };

  const formMethods = useForm({
    defaultValues,
    shouldUseNativeValidation: true,
  });
  const {
    fields: alternateNameFields,
    append,
    remove,
  } = useFieldArray({
    name: 'input.alternateNames',
    control: formMethods.control,
  });
  const { data } = usePerformingRightsOrganizationsQuery();
  const [createSongwriter, { loading }] = useCreateOutsideSongwriterMutation({
    onCompleted(d) {
      addCowriter({
        edit: true,
        saved: false,
        id: d?.createOutsideSongwriter?.songwriter?.id,
        percentage: null,
        label: [
          d?.createOutsideSongwriter?.songwriter?.firstName,
          d?.createOutsideSongwriter?.songwriter?.middleName,
          d?.createOutsideSongwriter?.songwriter?.lastName,
          `(${t('form.outside')})`,
        ].reduce((s: string, c) => s.concat(c ? ` ${c}` : ''), ''),
      });
      setOpen(false);
    },
  });

  const [publisherOpen, setPublisherOpen] = useState(false);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      fullScreen={!md}
      fullWidth
    >
      <form
        onSubmit={formMethods.handleSubmit((v) => {
          let variables = v;
          if (!publisherOpen) {
            variables = {
              input: {
                ...v.input,
                publishingCompany: undefined,
              },
            };
          }
          createSongwriter({ variables });
        })}
      >
        <Grid
          container
          sx={{
            padding: '2.5rem 2.5rem 0 2.5rem',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={5}>
            <Typography variant="h1" data-testid="dialog-title">
              {t('form.titles.modal')}
            </Typography>
          </Grid>
          <Grid container item xs={1} alignItems="center">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <FormProvider {...formMethods}>
            <Grid
              container
              item
              direction="column"
              xs={12}
              md={7}
              gap={2}
              margin={{ xs: 'auto', md: '1rem' }}
            >
              <FormInput
                id="email"
                name="songwriter.email"
                label={t('form.labels.modalEmail')}
                inputProps={{ autocomplete: 'email' }}
                type="email"
                autocomplete
                required
              />
              <FormInput
                id="firstName"
                name="songwriter.firstName"
                label={t('form.labels.modalFirstName')}
                inputProps={{
                  autoComplete: 'given-name',
                }}
                type="text"
                autocomplete
                required
              />
              <FormInput
                id="middleName"
                name="songwriter.middleName"
                label={t('form.labels.modalMiddleName')}
                inputProps={{
                  autocomplete: 'additional-name',
                }}
                type="text"
                autocomplete
              />
              <FormInput
                id="lastName"
                name="songwriter.lastName"
                label={t('form.labels.modalLastName')}
                inputProps={{
                  autocomplete: 'family-name',
                }}
                type="text"
                autocomplete
                required
              />
            </Grid>
            <Grid
              container
              item
              margin="1rem 0 1rem 1rem"
              sx={{ width: 'max-content' }}
            >
              <Button
                data-testid="add-alternate-name"
                variant="contained"
                startIcon={<AddIcon />}
                color="success"
                onClick={() => {
                  append('');
                }}
              >
                {t('form.buttons.modalAlternateName')}
              </Button>
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={12}
              md={7}
              gap={2}
              margin={{ xs: '1rem auto', md: '1rem' }}
            >
              {alternateNameFields.map((field, i) => {
                return (
                  <Grid key={field.id} container item xs={12} direction="row">
                    <FormInput
                      id={`non-songtrust-alternate-name-${field.id}`}
                      name={`songwriter.alternateNames.${i}.name`}
                      label={t('form.labels.modalAlternateName')}
                      sx={{ flexGrow: '1' }}
                      inputProps={{
                        'data-testid': `outside-alternate-name-${i}`,
                      }}
                      inputFullWidth
                    />
                    <IconButton
                      data-testid={`outside-alternate-name-close-${i}`}
                      onClick={() => remove(i)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={12}
              md={7}
              gap={2}
              margin={{ xs: 'auto', md: '1rem' }}
            >
              <FormSelect
                id="outside-pro"
                data-testid="outside-pro"
                name="pro.name"
                label={t('form.labels.outsideSongwriterPRO')}
                options={
                  data?.performingRightsOrganizations?.edges?.map((edge) => ({
                    choiceId: edge?.node?.id || '',
                    choiceLabel: edge?.node?.name || '',
                  })) || []
                }
                required
              />
              <FormInput
                id="non-songtrust-ipi"
                name="songwriter.ipi"
                label={t('form.labels.outsideSongwriterIPI')}
                required
              />
            </Grid>
            <Collapse in={publisherOpen} collapsedSize={0}>
              <Grid
                container
                item
                direction="column"
                xs={12}
                md={7}
                gap={2}
                margin={{ xs: '1rem auto', md: '1rem' }}
              >
                <FormInput
                  id="non-songtrust-publishing-company-name"
                  name="songwriter.publishingCompany.name"
                  label={t('form.labels.outsideSongwriterPublisher')}
                />
                <FormInput
                  id="non-songtrust-publishing-company-ipi"
                  name="songwriter.publishingCompany.ipi"
                  label={t('form.labels.outsideSongwriterPublisherIPI')}
                />
                <FormInput
                  id="non-songtrust-publishing-company-pro"
                  name="songwriter.publishingCompany.pro"
                  label={t('form.labels.outsideSongwriterPublisherEmail')}
                />
              </Grid>
            </Collapse>
            <Grid
              container
              item
              margin="1rem 0 1rem 1rem"
              sx={{ width: 'max-content' }}
            >
              <Button
                data-testid="toggle-publisher"
                variant={publisherOpen ? 'outlined' : 'contained'}
                startIcon={publisherOpen ? <RemoveIcon /> : <AddIcon />}
                color={publisherOpen ? 'inherit' : 'success'}
                onClick={() => {
                  setPublisherOpen((state) => {
                    if (state)
                      formMethods.setValue(
                        'input.publishingCompany',
                        undefined,
                      );
                    return !state;
                  });
                }}
              >
                {t(
                  `form.buttons.${
                    publisherOpen
                      ? 'outsideSongwriterNoPublisher'
                      : 'outsideSongwriterPublisher'
                  }`,
                )}
              </Button>
            </Grid>
          </FormProvider>
        </DialogContent>
        <DialogActions
          sx={{
            marginRight: md ? '4rem' : '0px',
            marginBottom: md ? '2rem' : 'auto',
            justifyContent: xs ? 'center' : 'flex-end',
          }}
        >
          <Button
            data-testid="close-dialog"
            variant="outlined"
            onClick={handleClose}
            sx={{
              paddingLeft: '2rem',
              paddingRight: '2rem',
              margin: '0 2rem',
            }}
          >
            {t('form.buttons.cancel')}
          </Button>
          <Button
            data-testid="submit-outside-songwriter"
            variant="contained"
            color="info"
            type="submit"
            sx={{
              paddingLeft: '2rem',
              paddingRight: '2rem',
              margin: '0 2remm',
            }}
            disabled={!formMethods.formState.isValid || loading}
          >
            {loading ? t('form.buttons.loading') : t('form.buttons.submit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
