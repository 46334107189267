import { useReactiveVar } from '@apollo/client';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { currentSessionTokenVar } from '~/cache';
import { Translator } from '~/types/Translator';
import ForgotPasswordPageForm from '../ForgotPasswordPageForm';
import ForgotPasswordSuccess from './ForgotPasswordPageSuccess';

function ForgotPasswordPage({ t }: Translator) {
  const navigate = useNavigate();
  const formMethods = useForm();
  const sessionToken = useReactiveVar(currentSessionTokenVar);

  const [resetSuccess, setResetSuccess] = useState<boolean>(false);

  useEffect(() => {
    document.title = `${t('page.title')} | ${import.meta.env.VITE_APP_TITLE}`;
    if (sessionToken) navigate('/dashboard');
  }, [sessionToken]);

  return (
    <div className="forgotpassword-page" data-testid="forgotpassword-page">
      <Container>
        {resetSuccess && <ForgotPasswordSuccess />}
        {!resetSuccess && (
          <Grid item xs={12} md={12}>
            {!resetSuccess && (
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  display: { xs: 'none', md: 'block' },
                  paddingTop: { md: '2rem' },
                  paddingBottom: { md: '4rem' },
                }}
              >
                {t('page.title')}
              </Typography>
            )}

            <Grid
              sx={{
                flexDirection: {
                  xs: 'column-reverse',
                  md: 'row',
                },
              }}
              container
            >
              <Grid item xs={12} md={6}>
                <Box
                  data-testid="forgotpassword-content"
                  sx={{
                    paddingTop: { xs: '2rem' },
                    paddingLeft: { md: '2rem' },
                  }}
                >
                  <Typography variant="h4" component="h2">
                    {t('page.content.subtitle')}
                  </Typography>
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                      paddingBottom: { md: '2rem' },
                    }}
                  >
                    {t('page.content.instruction')}
                  </Typography>
                  <Typography variant="body1">
                    {t('page.content.details')}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ paddingBottom: { xs: '2rem', md: '0' } }}
              >
                <FormProvider {...formMethods}>
                  <ForgotPasswordPageForm setResetSuccess={setResetSuccess} />
                </FormProvider>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default ForgotPasswordPage;
