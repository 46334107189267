import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormInput from '~/base/components/FormInput';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Translator } from '~/types/Translator';

interface SongInformationProps extends Translator {
  loading: boolean;
}

function SongInformation({ t, loading = false }: SongInformationProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'alternatetitleSet',
    control,
  });
  return (
    <Grid container gap={2}>
      <Box sx={{ width: '100%' }}>
        <SectionTitle>{t('form.titles.information')}</SectionTitle>
      </Box>
      <Grid item xs={12}>
        <FormInput
          id="song-title"
          label={t('form.labels.title')}
          disabled={loading}
          name="title"
          sx={{
            width: '100%',
            mb: 2,
          }}
          inputProps={{ 'data-testid': 'song-title-input' }}
          inputFullWidth
          displayRequired
          tooltipLabel={t('form.labels.whyAlternateTitle')}
          required={{
            value: true,
            message: t('form.errors.songTitleRequired'),
          }}
          clearErrorOnFocus
        />
      </Grid>
      {fields.map((field, i) => {
        return (
          <Grid key={field.id} item xs={12}>
            <Box
              sx={{
                width: '100%',
                justifyContent: 'center',
                justifyItems: 'center',
                display: 'flex',
              }}
            >
              <FormInput
                key={field.id}
                id={`alternatetitleSet-${i}-title`}
                name={`alternatetitleSet.${i}.title`}
                label={t('form.labels.alternateTitle')}
                sx={{ flexGrow: '1', width: '90%', pr: 1 }}
                inputProps={{ 'data-testid': `alternate-title-${i}` }}
                inputFullWidth
                clearErrorOnFocus
              />
              <Box sx={{ pt: 3 }}>
                <IconButton
                  data-testid={`delete-alternate-title-${i}`}
                  onClick={() => remove(i)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Box sx={{ width: '100%', pt: 2 }}>
          <Button
            data-testid="add-alternate-title"
            variant="outlined"
            color="success"
            startIcon={<AddIcon />}
            disabled={loading}
            onClick={() => append({ title: '' })}
          >
            {t('form.buttons.alternateTitle')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SongInformation;
