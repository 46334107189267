import { Alert, Button, Grid, Stack, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const enum FormBannerType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

interface FormBannerProps {
  text: string;
  type: FormBannerType;
  time?: number;
  dismissButton?: boolean;
  sx?: SxProps<Theme>;
}

/**
 * FormBanner component displays a banner with a message and optional dismiss button.
 *
 * @param {string} text - The message text to display.
 * @param {FormBannerType} type - The type of banner (success, error, info, warning).
 * @param {number} [time=undefined] - The time in milliseconds to auto-hide the banner.
 * @param {SxProps<Theme>} [sx={}] - The styling properties.
 * @param {boolean} [dismissButton=false] - Flag indicating whether to show a dismiss button.
 * @returns {JSX.Element} The rendered banner component.
 */
function FormBanner({
  text,
  type,
  time = undefined,
  sx = {},
  dismissButton = false,
}: FormBannerProps): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { t } = useTranslation(['translation']);

  // Merging the passed sx prop with default styles
  const boxStyle = {
    padding: { xs: '1rem' },
    ...sx,
  };

  // Handler for closing the banner
  const handleOnClose = () => {
    setIsVisible(false);
  };

  // Effect to handle visibility and auto-hide based on the time prop
  useEffect(() => {
    if (time !== undefined) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, time);
    } else {
      setIsVisible(true);
    }
  }, [time]);

  return (
    <Grid data-testid="form-banner" container>
      {isVisible && text !== '' && (
        <Stack sx={{ width: '100%' }} justifyContent="space-between">
          <Alert
            data-testid="form-banner-text"
            severity={type}
            sx={boxStyle}
            action={
              dismissButton ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  sx={{
                    pl: '2rem',
                    pr: '2rem',
                  }}
                  onClick={handleOnClose}
                >
                  {t('button.dismiss')}
                </Button>
              ) : undefined
            }
          >
            {text}
          </Alert>
        </Stack>
      )}
    </Grid>
  );
}

export default FormBanner;
