import { Button, Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RESET_PASSWORD_FORM_URL } from '~/base/constants/baseConstants';

function ForgotPasswordPageSuccess() {
  const NAMESPACE = 'forgotpassword';
  const { t } = useTranslation([NAMESPACE]);
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography
          data-testid="forgotpassword-success-title"
          variant="h1"
          component="h1"
          sx={{
            display: { xs: 'none', md: 'block' },
            paddingTop: { md: '2rem' },
          }}
        >
          {t('page.title-success')}
        </Typography>
      </Grid>

      <Grid item xs={12} container>
        <Grid
          data-testid="forgotpassword-success-content"
          item
          xs={12}
          sx={{
            paddingTop: { xs: '2rem' },
            paddingLeft: { md: '2rem' },
          }}
        >
          <Trans
            ns={NAMESPACE}
            i18nKey="page.content.success"
            components={{ link1: <Link href={RESET_PASSWORD_FORM_URL} /> }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-around"
            sx={{
              paddingTop: { xs: '2rem' },
            }}
          >
            <Button
              data-testid="forgotpassword-success-button"
              variant="contained"
              color="success"
              type="submit"
              sx={{
                padding: '1rem 4rem',
              }}
              onClick={(event) => {
                event.preventDefault();
                navigate('/login');
              }}
            >
              {t('page.content.success-button')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPasswordPageSuccess;
