import { Grid, Typography } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useFormContext } from 'react-hook-form';
import validator from 'validator';
import FormInput from '~/base/components/FormInput';
import { passwordRegex } from '~/constants/regex';
import { Translator } from '~/types/Translator';
import SignupStepNumberCheck from '../SignupStepNumberCheck';

function SignupAccountInformation({ t }: Translator) {
  const { getValues, formState } = useFormContext();

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={3}
      className="signup-page-account-information"
      data-testid="signup-page-account-information"
    >
      <Grid item xs={12} md={12}>
        <Typography variant="h1" component="h2">
          <SignupStepNumberCheck
            text="1"
            active={formState.isDirty}
            validated={Object.keys(formState.touchedFields).length >= 6}
          />
          {t('form.account-info')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          sx={{
            width: '100%',
          }}
          id="signup-firstname"
          data-testid="signup-firstname"
          name="firstname"
          label={t('form.fields.firstname.label')}
          required={{
            value: true,
            message: t('form.fields.firstname.validation.required'),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          sx={{
            width: '100%',
          }}
          id="signup-lastname"
          data-testid="signup-lastname"
          name="lastname"
          label={t('form.fields.lastname.label')}
          required={{
            value: true,
            message: t('form.fields.lastname.validation.required'),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          sx={{
            width: '100%',
          }}
          id="signup-email"
          data-testid="signup-email"
          name="email"
          label={t('form.fields.email.label')}
          required={{
            value: true,
            message: t('form.fields.email.validation.required'),
          }}
          validate={{
            isEmailValid: (v) =>
              validator.isEmail(v as string) ||
              t('form.fields.email.validation.invalid'),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          sx={{
            width: '100%',
          }}
          id="signup-phone"
          data-testid="signup-phone"
          name="phone"
          label={t('form.fields.phone.label')}
          required={{
            value: true,
            message: t('form.fields.phone.validation.required'),
          }}
          validate={{
            isValidPhone: (v) =>
              isValidPhoneNumber(v as string) ||
              t('form.fields.phone.validation.invalid'),
          }}
          isPhoneNumber
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          sx={{
            width: '100%',
          }}
          id="signup-password"
          data-testid="signup-password"
          name="password"
          label={t('form.fields.password.label')}
          required={{
            value: true,
            message: t('form.fields.password.validation.required'),
          }}
          tooltip={t('form.fields.password.tooltip')}
          type="password"
          validate={{
            isComplexPassword: (v) =>
              passwordRegex.test(v as string) ||
              (t('form.fields.password.validation.complexity') as string),
          }}
          autocomplete={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          sx={{
            width: '100%',
          }}
          id="signup-passwordconfirm"
          data-testid="signup-passwordconfirm"
          name="passwordconfirm"
          label={t('form.fields.passwordconfirm.label')}
          required={{
            value: true,
            message: t('form.fields.passwordconfirm.validation.required'),
          }}
          tooltip={t('form.fields.passwordconfirm.tooltip')}
          type="password"
          validate={{
            isMatchingPassword: (v) =>
              v === getValues('password') ||
              (t(
                'form.fields.passwordconfirm.validation.nonmatching',
              ) as string),
          }}
          autocomplete={false}
        />
      </Grid>
    </Grid>
  );
}

export default SignupAccountInformation;
