import { useQuery } from '@apollo/client';
import { Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { loggedInSongtrustUserOnboardingResponseVar } from '~/cache';
import usePageTitle from '~/hooks/usePageTitle';
import OnboardingFormContext from '~/onboarding/components/OnboardingFormContext';
import OnboardingStepper from '~/onboarding/components/OnboardingStepper/OnboardingStepper';

import {
  OnboardingFieldsDocument,
  OnboardingFieldsQuery,
} from '~/types/generated/graphql';

function OnboardingPage() {
  const { t } = useTranslation(['onboarding']);
  usePageTitle(t('form.title'));

  const totalSteps = 3;
  const [activeStep, setActiveStep] = React.useState(0);

  const { loading, data, fetchMore } = useQuery<OnboardingFieldsQuery>(
    OnboardingFieldsDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: { countryCursor: undefined },
      onCompleted: () => {
        loggedInSongtrustUserOnboardingResponseVar(data);
      },
      onError: () => {
        loggedInSongtrustUserOnboardingResponseVar(undefined);
      },
    },
  );

  const updateQuery = (
    previousQueryResult: OnboardingFieldsQuery,
    { fetchMoreResult }: { fetchMoreResult: OnboardingFieldsQuery },
  ) => {
    if (!fetchMoreResult) return previousQueryResult;

    const combined = {
      ...previousQueryResult,
      countries: {
        pageInfo: fetchMoreResult?.countries?.pageInfo,
        edges: [
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...previousQueryResult?.countries?.edges,
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...fetchMoreResult?.countries?.edges,
        ],
      },
    };
    return combined as OnboardingFieldsQuery;
  };

  useEffect(() => {
    if (data && fetchMore) {
      if (data?.countries?.pageInfo.hasNextPage) {
        fetchMore({
          updateQuery,
          variables: { countryCursor: data?.countries?.pageInfo.endCursor },
        });
      }
    }
  }, [data, fetchMore]);

  return (
    <div className="onboarding-page" data-testid="onboarding-page">
      <Container>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              data-testid="onboarding-page-title"
              variant="h1"
              component="h1"
              sx={{
                paddingTop: { md: '2rem' },
                paddingBottom: { md: '4rem' },
              }}
            >
              {t('form.title')} {activeStep + 1}/{totalSteps}
            </Typography>
          </Grid>

          {loading && <LoadingIndicator size={100} />}
          {!loading && data && (
            <Grid item xs={12} md={12}>
              <OnboardingFormContext.Provider value={data}>
                <OnboardingStepper
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              </OnboardingFormContext.Provider>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default OnboardingPage;
