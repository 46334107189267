import { DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import { Dispatch, InputHTMLAttributes, SetStateAction } from 'react';
import { T } from '~/types/Translator';

export default function PreSubmissionModal({
  t,
  postSong,
  preSubmission,
  setPreSubmission,
}: {
  t: T;
  postSong: () => void;
  preSubmission: { open: boolean; agreed: boolean };
  setPreSubmission: Dispatch<
    SetStateAction<{ open: boolean; agreed: boolean }>
  >;
}) {
  return (
    <Dialog open={preSubmission.open}>
      <DialogTitle variant="h2">{t('form.titles.preSubmission')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${t('form.copy.preSubmission')} `}
          <Link target="_blank" href="/terms-of-service">
            {t('form.copy.preSubmissionAgree')}
          </Link>
          {` ${t('form.copy.preSubmission2')}`}
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={preSubmission.agreed}
              inputProps={
                {
                  'data-testid': 'pre-submission-checkbox',
                } as InputHTMLAttributes<HTMLInputElement>
              }
              onChange={({ target: { checked } }) =>
                setPreSubmission((state) => ({ ...state, agreed: checked }))
              }
            />
          }
          label={t('form.labels.copyrightInfringement')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() =>
            setPreSubmission((state) => ({ ...state, open: false }))
          }
        >
          {t('form.buttons.cancel')}
        </Button>
        <Button
          data-testid="final-submission-button"
          variant="contained"
          color="success"
          disabled={!preSubmission.agreed}
          onClick={() => {
            if (preSubmission.agreed) {
              postSong();
              setPreSubmission((state) => ({ ...state, open: false }));
            }
          }}
        >
          {t('form.buttons.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
