import { createTheme } from '@mui/material';

const fontFamily = ['National2', 'Arial', 'sans-serif'].join(',');
const fontFamilyBold = ['National2Bold', 'Arial', 'sans-serif'].join(',');
const fontFamilyNarrowBold = [
  'National2NarrowBold',
  'Arial',
  'sans-serif',
].join(',');

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    huge: true;
  }
}

/**
 * The below was originally generated from the following resources, custom additions have been added for style overrides.
 * https://bareynol.github.io/mui-theme-creator
 * https://www.sketch.com/s/87459181-f369-4131-8a91-d088457eb62c/a/R1Krory
 * https://www.sketch.com/s/87459181-f369-4131-8a91-d088457eb62c/a/WKxV3D2
 */
const defaultTheme = createTheme({
  components: {
    // SvgIcon
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'huge' },
          style: {
            fontSize: '3rem',
          },
        },
      ],
    },
    // Stepper
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#a4a4a4',
          height: '2.5rem',
          width: '2.5rem',
          '&.Mui-active': {
            color: '#031c9b',
          },
          '&.Mui-completed': {
            color: '#031c9b',
          },
        },
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: fontFamilyBold,
          fontWeight: 'bold',
          fontSize: '1.125rem',
          color: '#000',
        },
      },
    },

    // Links
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#D5164B',
          textDecorationColor: 'inherit',
        },
      },
    },

    // Buttons
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 32,
          boxShadow: 'none',
        },
        outlinedPrimary: {
          borderColor: '#000',
          borderWidth: '.125rem',
          '&:hover': {
            borderWidth: '.125rem',
          },
          '&:disabled': {
            borderWidth: '.125rem',
          },
        },
        containedSuccess: {
          color: '#FFF',
          backgroundColor: '#B4012D',
          borderColor: '#B4012D',
          '&:hover': {
            color: '#FFF',
            borderColor: '#B4012D',
            backgroundColor: '#B4012D',
          },
          textAlign: 'center',
          textDecorationColor: 'inherit',
          textTransform: 'uppercase',
        },
        outlinedSuccess: {
          color: '#B4012D',
          borderColor: '#B4012D',
          '&:hover': {
            color: '#B4012D',
            borderColor: '#B4012D',
          },
          textAlign: 'center',
          textDecorationColor: 'inherit',
          textTransform: 'uppercase',
        },
        outlinedSecondary: {},
        containedPrimary: {},
        containedSecondary: {},
        outlinedInfo: {
          backgroundColor: '#E0E0E0',
          borderColor: '#E0E0E0',
          color: '#000',
          '&:hover': {
            borderColor: '#E0E0E0',
            background: '#000',
            color: '#fff',
          },
        },
        containedInfo: {
          backgroundColor: '#031C9B',
          color: '#fff',
          ':hover': {
            background: '#000',
            color: '#fff',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          marginLeft: 0,
          paddingLeft: '.25rem',
          paddingRight: '.25rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          li: {
            '&.MuiAutocomplete-option&.Mui-selected': {
              backgroundColor: '#031c9b',
              color: '#FFF',
            },
            '&.MuiAutocomplete-option[aria-selected="true"], &.MuiAutocomplete-option[aria-selected="true"].Mui-focused':
              {
                backgroundColor: '#031c9b',
                color: '#FFF',
              },
            '&.MuiAutocomplete-option.Mui-focused': {
              backgroundColor: '#031c9b',
              color: '#FFF',
            },
          },
        },
        input: {
          padding: 0,
        },
        inputRoot: {
          padding: '6px',
          fieldset: {
            border: '1px solid #7E7E7E',
            borderRadius: '.5rem',
          },
        },
        root: {
          marginTop: '1rem',
          '&.Mui-focused': {
            borderColor: '#031C9B',
            borderWidth: '2px',
            fieldset: {
              borderColor: '#031C9B',
              '&.MuiOutlinedInput-notchedOutline': {
                borderColor: '#031C9B',
                borderWidth: '2px',
              },
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            background: 'transparent',
            color: '#000',
          },
        },
        icon: {
          position: 'relative',
          marginLeft: '-22px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#031c9b',
            color: '#fff',
            '&.Mui-focusVisible': {
              backgroundColor: '#031c9b',
              color: '#fff',
            },
            '&:hover': {
              backgroundColor: '#031c9b',
              color: '#fff',
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: '#031c9b',
            color: '#fff',
          },
          '&:hover': {
            backgroundColor: '#031c9b',
            color: '#fff',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            borderColor: '#F33126',
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&.Mui-error, &.Mui-error .MuiRadio-root, &.Mui-error .MuiCheckbox-root, &.Mui-error .MuiChip-root':
            {
              color: '#F33126',
            },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: false,
        root: {
          fontSize: '0.75rem',
          top: '-1.5rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.Mui-focused': {
            borderColor: '#031C9B',
            borderWidth: '2px',
            '& > fieldset': {
              borderColor: '#031C9B',
              borderWidth: '2px',
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#606BF8',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#606BF8',
          },
        },
      },
    },

    // Fonts
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'National2';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('National2'), local('National2-Regular'), url(${
            import.meta.env.VITE_CDN_URL
          }fonts/national-2-web-regular.woff2?version=${
            import.meta.env.VITE_BUILD_TIMESTAMP
          }) format('woff2');
        }
        @font-face {
          font-family: 'National2Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('National2Bold'), local('National2-Bold'), url(${
            import.meta.env.VITE_CDN_URL
          }fonts/national-2-web-bold.woff2?version=${
            import.meta.env.VITE_BUILD_TIMESTAMP
          }) format('woff2');
        }
        @font-face {
          font-family: 'National2NarrowBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('National2NarrowBold'), local('National2-NarrowBold'), url(${
            import.meta.env.VITE_CDN_URL
          }fonts/national-2-narrow-web-bold.woff2?version=${
            import.meta.env.VITE_BUILD_TIMESTAMP
          }) format('woff2');
        }
        @font-face {
          font-family: 'DawningOfANewDay';
          font-style: cursive;
          font-weight: 700;
          src: local('DawningOfANewDay'), local('DawningOfANewDay'), url(${
            import.meta.env.VITE_CDN_URL
          }fonts/DawningofaNewDay.ttf?version=${
            import.meta.env.VITE_BUILD_TIMESTAMP
          }) format('ttf');
        }
      `,
    },
  },

  // Color Palette
  palette: {
    primary: {
      main: '#000000',
      light: '#4d4d4d',
    },
    secondary: {
      main: '#ffd71c',
      light: '#ffeb8d',
      dark: '#bfa116',
      contrastText: '#000000',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#4d4d4d',
      disabled: '#4d4d4d',
    },
    error: {
      main: '#f33126',
      light: '#f8837d',
      dark: '#ba3310',
      contrastText: '#000000',
    },
    info: {
      main: '#52bcd6',
      light: '#bae4ef',
      dark: '#669791',
      contrastText: '#000000',
    },
    success: {
      main: '#7fc832',
      light: '#b2de84',
      dark: '#498852',
      contrastText: '#000000',
    },
    divider: '#7e7e7e',
  },

  // Typography
  typography: {
    h1: {
      fontFamily: fontFamilyBold,
      fontWeight: 700,
      fontSize: '2rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    h2: {
      fontFamily: fontFamilyBold,
      fontWeight: 400,
      fontSize: '1.5rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    h3: {
      fontFamily: fontFamilyBold,
      fontWeight: 700,
      fontSize: '1.5rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    h4: {
      fontFamily,
      fontWeight: 400,
      fontSize: '1.375rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    h5: {
      fontFamily: fontFamilyNarrowBold,
      fontWeight: 700,
      fontSize: '1.25rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    h6: {
      fontFamily,
      fontWeight: 400,
      fontSize: '1.125rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    subtitle1: {
      fontFamily,
      fontWeight: 400,
      fontSize: '1rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    subtitle2: {
      fontFamily: fontFamilyBold,
      fontWeight: 700,
      fontSize: '0.875rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    body1: {
      fontFamily,
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily,
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    button: {
      fontFamily: fontFamilyBold,
      fontWeight: 700,
      fontSize: '0.875rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily,
      fontWeight: '400',
      fontSize: '0.75rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    overline: {
      fontFamily,
      fontWeight: '0.625rem',
      fontSize: 10,
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
  },
});

export default defaultTheme;
