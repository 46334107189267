import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import accountConstants from '~/account/constants/accountConstants';
import FullScreenMessage from '~/base/components/FullScreenMessage/FullScreenMessage';
import SectionTipCard from '~/base/components/SectionTipCard';
import { currentSongtrustUserPersonaVar } from '~/cache';
import usePageTitle from '~/hooks/usePageTitle';
import {
  useLoggedInSongtrustUserPersonaLazyQuery,
  useTrolleyStringQuery,
} from '~/types/generated/graphql';

function TaxDocumentationPage() {
  const navigate = useNavigate();
  const { t } = useTranslation(['account']);
  const { data } = useTrolleyStringQuery({
    fetchPolicy: 'network-only',
  });

  // Function to re-poll loggedInUserPersona, call this after event emitted from trolley a
  // and update the reactive var so the user can navigate the site after the webhook is hit.
  const [reloadLoggedInUserPersona] = useLoggedInSongtrustUserPersonaLazyQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 2000,
    onCompleted: (res) => {
      currentSongtrustUserPersonaVar(res);
    },
  });

  const [searchParams] = useSearchParams();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    searchParams.get('q') === 'showDialog',
  );
  const handleModalClose = () => {
    setIsModalOpen(!isModalOpen);
    navigate(accountConstants.taxSettingsRoute);
  };

  usePageTitle(t('sections.tax-information.title'));

  window.addEventListener('message', (e) => {
    const widgetEvent = e.data;
    if (widgetEvent.document) {
      const widgetHeight = widgetEvent.document.height + 50;
      if (widgetEvent.event === 'document.height') {
        const iframeElement = document.getElementById('trolley-frame');

        if (iframeElement) {
          iframeElement.style.height = `${widgetHeight}px`;
        }
      }
    } else if (widgetEvent.account || widgetEvent.taxForm) {
      // Call function to poll persona updates.
      reloadLoggedInUserPersona();
    }
  });

  return (
    <div className="tax-page" data-testid="tax-documentation-page">
      <FullScreenMessage
        isOpen={isModalOpen}
        allowBackdropClose={false}
        allowEscClose={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" component="h1">
              {t('sections.tax-information.modal.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              {t('sections.tax-information.modal.content')}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t('sections.tax-information.modal.button')}
            </Button>
          </Grid>
        </Grid>
      </FullScreenMessage>

      {(data?.trolleyString && (
        <Container>
          <Grid
            container
            sx={{
              flexDirection: {
                xs: 'column',
              },
            }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  pt: { xs: '1rem', md: '2rem' },
                  pb: { xs: '1rem', md: '2rem' },
                }}
              >
                {t('sections.tax-information.title')}
              </Typography>
            </Grid>

            <Grid
              container
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Grid item xs={12} md={9}>
                {/* pl and pr deviates from DashboardPage to compensatefor for padding inside 3rd party iframe */}
                <Box
                  data-testid="tax-page-content"
                  sx={{
                    pl: { md: '1rem' },
                    pr: { md: '3rem' },
                    pt: { xs: '2rem', md: '0' },
                  }}
                >
                  <iframe
                    id="trolley-frame"
                    title="Trolley IFrame Component"
                    scrolling="no" // No better way of doing this consistently
                    src={data?.trolleyString}
                    width="100%"
                    style={{ border: 0, padding: 0 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container rowSpacing={4}>
                  <Grid item>
                    <SectionTipCard
                      title={t('sections.tax-information.trolley.title')}
                    >
                      <div>
                        <span>
                          {t('sections.tax-information.trolley.content.header')}
                        </span>
                        <ul>
                          <li>
                            <div>
                              {t(
                                'sections.tax-information.trolley.content.bank-transfer.title',
                              )}
                            </div>
                            <div>
                              {t(
                                'sections.tax-information.trolley.content.bank-transfer.explanation',
                              )}
                            </div>
                          </li>
                          <li>
                            {t(
                              'sections.tax-information.trolley.content.paypal',
                            )}
                          </li>
                        </ul>
                        <div style={{ fontStyle: 'italic' }}>
                          {t('sections.tax-information.trolley.content.notes')}
                        </div>
                        <div>
                          {t('sections.tax-information.trolley.help')}
                          <Link href={accountConstants.helpCenterPaymentsLink}>
                            {t(
                              'sections.tax-information.trolley.help-link-text',
                            )}
                          </Link>
                          .
                        </div>
                      </div>
                    </SectionTipCard>
                  </Grid>
                  <Grid item>
                    <SectionTipCard
                      title={t('sections.tax-information.payments.title')}
                      color="blue"
                    >
                      <div>
                        <div>
                          {t('sections.tax-information.payments.explanation')}
                        </div>
                        <br />
                        <div>
                          {t('sections.tax-information.payments.submit')}
                        </div>
                        <br />
                        <div>
                          {t('sections.tax-information.payments.help')}
                          <Link
                            href={accountConstants.helpCenterTaxInformationLink}
                          >
                            {t(
                              'sections.tax-information.payments.help-link-text',
                            )}
                          </Link>
                          .
                        </div>
                      </div>
                    </SectionTipCard>
                  </Grid>

                  <Grid item>
                    <SectionTipCard
                      title={t('sections.tax-information.schedule.title')}
                      color="green"
                    >
                      <div>
                        <div>
                          {t('sections.tax-information.schedule.explanation')}
                        </div>
                        <br />
                        <div>
                          {t('sections.tax-information.schedule.history')}
                          <Link
                            component={RouterLink}
                            to={accountConstants.accountHistoryRoute}
                          >
                            {t(
                              'sections.tax-information.schedule.history-link-text',
                            )}
                          </Link>
                          .
                        </div>
                      </div>
                    </SectionTipCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )) || <div />}
    </div>
  );
}

export default TaxDocumentationPage;
