import { Box, Grid, Typography } from '@mui/material';
import shuffle from 'knuth-shuffle-seeded';
import _, { map, startCase } from 'lodash';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import FormInput from '~/base/components/FormInput';
import FormMultiCheckbox from '~/base/components/FormMultiCheckbox';
import FormRadio from '~/base/components/FormRadio';
import FormSelect from '~/base/components/FormSelect';
import OnboardingFormContext from '~/onboarding/components/OnboardingFormContext';

function OnboardingAboutQuestions() {
  const { t } = useTranslation(['onboarding']);
  const { getValues } = useFormContext();

  const isSelfDistributed = (value: string): boolean =>
    value === 'self-distributed';
  const isUSSelectedCountry = (value: string): boolean => value === 'US';

  const [
    shouldDisplaySelfDistributedQuestions,
    setShouldDisplaySelfDistributedQuestions,
  ] = useState(isSelfDistributed(getValues('digitalDistribution')));
  const [shouldDisplayDropDownStates, setShouldDisplayDropDownStates] =
    useState(isUSSelectedCountry(getValues('country')));

  const handleDigitalDistribution = (event: SyntheticEvent, value: string) => {
    setShouldDisplaySelfDistributedQuestions(isSelfDistributed(value));
  };

  const handleSelectCountry = (value: string) => {
    setShouldDisplayDropDownStates(isUSSelectedCountry(value));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const shuffleSeed = React.useMemo(() => {
    return _.random(0, 1000000);
  }, []);

  return (
    <OnboardingFormContext.Consumer>
      {({
        distributorsChoices,
        digitalDistributionChoices,
        yesNoChoices,
        countries,
        userProfileTypes,
        states,
      }) => (
        <Box
          data-testid="onboarding-about-questions"
          sx={{
            paddingLeft: '.5rem',
            paddingTop: { md: '2rem' },
            paddingBottom: { md: '2rem' },
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1rem 0 1rem' }}
          >
            {t('form.questions.about-you.account-type')}
          </Typography>
          <FormSelect
            id="accountType"
            name="accountType"
            data-testid="account-type"
            label={t('form.label.account-type')}
            required
            options={
              userProfileTypes?.edges?.map((edge) => ({
                choiceId: edge?.node?.id as string,
                choiceLabel: edge?.node?.title as string,
              })) || []
            }
            sx={{ width: { xs: '100%', md: '31.625rem' } }}
            tooltip={t('form.tooltips.account-type')}
            placeholder={t('form.placeholder.account-type')}
          />

          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.about-you.location')}
          </Typography>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} md={6} lg="auto">
              <FormAutoComplete
                id="country"
                name="country"
                testid="country"
                label={t('form.label.country')}
                required
                placeholder={t('form.placeholder.country')}
                options={
                  countries?.edges
                    .map((edge) => ({
                      choiceId: edge?.node?.isoCode as string,
                      choiceLabel: startCase(
                        edge?.node?.name.toLowerCase(),
                      ) as string,
                    }))
                    .sort((a, b) =>
                      a.choiceLabel.localeCompare(b.choiceLabel),
                    ) || []
                }
                tooltip={t('form.tooltips.country')}
                onOptionSelected={handleSelectCountry}
                sx={{ width: { lg: '300px', xs: '100%' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg="auto">
              <FormInput
                id="city"
                name="city"
                data-testid="city"
                label={t('form.label.city')}
                required
                validate={{
                  validAmount: (v) => (v as string).length <= 100 || '',
                }}
                placeholder={t('form.placeholder.city')}
                tooltip={t('form.tooltips.city')}
                sx={{ width: { xs: '100%', lg: 'auto' } }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg="auto">
              {!shouldDisplayDropDownStates && (
                <FormInput
                  id="state"
                  name="state"
                  data-testid="state-input"
                  label={t('form.label.state')}
                  required
                  validate={{
                    validAmount: (v) => (v as string).length <= 100 || '',
                  }}
                  placeholder={t('form.placeholder.state-input')}
                  tooltip={t('form.tooltips.state')}
                  sx={{ width: { xs: '100%', lg: 'auto' } }}
                />
              )}
              {shouldDisplayDropDownStates && (
                <FormSelect
                  id="state"
                  name="state"
                  data-testid="state-dropdown"
                  label={t('form.label.state')}
                  required
                  placeholder={t('form.placeholder.state-select')}
                  options={map(states?.edges, (edge) => ({
                    choiceId: edge?.node?.isoCode as string,
                    choiceLabel: edge?.node?.name as string,
                  })).sort((a, b) =>
                    a.choiceLabel.localeCompare(b.choiceLabel),
                  )}
                  tooltip={t('form.tooltips.state')}
                  sx={{ width: { xs: '100%', lg: 'auto' } }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg="auto">
              <FormInput
                id="postalcode"
                name="postalcode"
                data-testid="postalcode"
                label={t('form.label.postalcode')}
                required
                validate={{
                  validAmount: (v) => (v as string).length <= 100 || '',
                }}
                placeholder={t('form.placeholder.postalcode')}
                tooltip={t('form.tooltips.postalcode')}
                sx={{ width: { xs: '100%', lg: 'auto' } }}
              />
            </Grid>
          </Grid>

          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.about-you.representation')}
          </Typography>
          <FormRadio
            id="globalPublisher"
            name="globalPublisher"
            data-testid="global-publisher"
            columns={3}
            required
            options={map(yesNoChoices?.edges, (edge) => ({
              choiceId: edge?.node?.choice as string,
              choiceLabel: t(`form.options.${edge?.node?.choice}`, {
                defaultValue: edge?.node?.label as string,
              }),
            }))}
            sx={{ width: '50%' }}
          />
          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.about-you.distribution')}
          </Typography>
          <FormRadio
            id="digitalDistribution"
            data-testid="digital-distribution"
            name="digitalDistribution"
            columns={1}
            onOptionSelected={handleDigitalDistribution}
            required
            options={map(digitalDistributionChoices?.edges, (edge) => ({
              choiceId: edge?.node?.choice as string,
              choiceLabel: t(`form.label.${edge?.node?.choice}`, {
                defaultValue: edge?.node?.label as string,
              }),
            }))}
            sx={{ width: '100%' }}
          />
          {shouldDisplaySelfDistributedQuestions && (
            <>
              <Typography
                variant="h4"
                component="h3"
                sx={{ margin: '1.75rem 0 1rem' }}
              >
                {t('form.questions.about-you.distribution-which')}
              </Typography>
              <FormMultiCheckbox
                name="currentDistributors"
                id="currentDistributors"
                data-testid="current-distributors"
                columns={4}
                required
                validate={{
                  validAmount: (v) =>
                    ((v as string[]).length > 0 &&
                      (v as string[]).length <= 3) ||
                    '',
                }}
                options={shuffle(
                  map(distributorsChoices?.edges, (edge) => ({
                    choiceId: `${edge?.node?.choice}`,
                    choiceLabel: edge?.node?.label as string,
                  })),
                  shuffleSeed,
                )}
              />

              <Typography
                variant="h4"
                component="h3"
                sx={{ margin: '1.75rem 0 1rem' }}
              >
                {t('form.questions.about-you.distribution-favorite')}
              </Typography>
              <FormRadio
                name="favoriteDistributor"
                id="favoriteDistributor"
                data-testid="favorite-distributor"
                columns={4}
                required
                options={shuffle(
                  map(distributorsChoices?.edges, (edge) => ({
                    choiceId: `${edge?.node?.choice}`,
                    choiceLabel: edge?.node?.label as string,
                  })),
                  shuffleSeed,
                )}
              />
            </>
          )}
        </Box>
      )}
    </OnboardingFormContext.Consumer>
  );
}

export default OnboardingAboutQuestions;
