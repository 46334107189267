import { Grid, Typography } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import FormRadio from '~/base/components/FormRadio';
import FormSelect from '~/base/components/FormSelect';
import { Translator } from '~/types/Translator';

type AddSongtrustWriterUSRepresentationProps = Translator;
function AddSongtrustWriterUSRepresentation({
  t,
}: AddSongtrustWriterUSRepresentationProps) {
  const [hasUSPRO, setHasUSPRO] = useState<boolean>();
  const [hasUsedPublisherToRegister, setHasUsedPublisherToRegister] =
    useState<boolean>();

  const yesNoChoices = [
    {
      choiceId: 'yes',
      choiceLabel: t('page.add-songwriter.form.songwriter.representation.yes'),
    },
    {
      choiceId: 'no',
      choiceLabel: t('page.add-songwriter.form.songwriter.representation.no'),
    },
  ];

  const usPROChoices = [
    { choiceId: 'ASCAP', choiceLabel: 'ASCAP' },
    { choiceId: 'BMI', choiceLabel: 'BMI' },
  ];

  const handleUSProAffiliationAnswer = (
    event: SyntheticEvent,
    value: string,
  ) => {
    setHasUSPRO(value === 'yes');
  };

  const handleUsedPublisherToRegister = (
    event: SyntheticEvent,
    value: string,
  ) => {
    setHasUsedPublisherToRegister(value === 'yes');
  };

  return (
    <Grid
      container
      spacing={2}
      data-testid="add-songtrust-writer-us-representation"
      sx={{ pb: '2rem', pt: '2rem' }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component="h1"
          sx={{ borderBottom: '1px solid #000' }}
        >
          {t('page.add-songwriter.form.songwriter.representation.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="p">
          {t(
            'page.add-songwriter.form.songwriter.representation.are-you-affiliated',
          )}
        </Typography>
        <FormRadio
          id="us-pro-affiliation"
          name="us-pro-affiliation"
          options={yesNoChoices}
          onOptionSelected={handleUSProAffiliationAnswer}
          columns={2}
          required
        />
      </Grid>

      {hasUSPRO === false && (
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            {t(
              'page.add-songwriter.form.songwriter.representation.prior-publisher-registration',
            )}
          </Typography>
          <FormRadio
            id="us-pro-affiliation-via-publisher"
            name="us-pro-affiliation-via-publisher"
            onOptionSelected={handleUsedPublisherToRegister}
            options={yesNoChoices}
            columns={2}
            required
          />
        </Grid>
      )}

      {hasUSPRO === false && hasUsedPublisherToRegister === false && (
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="p"
            data-testid="ascap-pro-preselected"
          >
            {t(
              'page.add-songwriter.form.songwriter.representation.ascap-registration',
            )}
          </Typography>
        </Grid>
      )}

      {(hasUSPRO || hasUsedPublisherToRegister) && (
        <Grid item xs={12}>
          <FormSelect
            id="pro-representation"
            name="pro-representation"
            data-testid="us-pro-representation-dropdown"
            required
            options={usPROChoices}
            sx={{ width: { xs: '100%', lg: 'auto' } }}
            label={t(
              'page.add-songwriter.form.songwriter.representation.us-representation',
            )}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default AddSongtrustWriterUSRepresentation;
