import { Button, Grid, Typography } from '@mui/material';
import { Translator } from '~/types/Translator';
import termsPdf from '~/assets/pdf/Songtrust_Terms_of_Service_Mar_08_2023.pdf';

function TermsPageTitleButtons({ t }: Translator) {
  const downloadPdfClickHandler = () => {
    fetch(termsPdf).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Songtrust_Terms_of_Service_Mar_08_2023.pdf';
        a.click();
      });
    });
  };

  return (
    <Grid
      data-testid="terms-page-title"
      container
      sx={{
        paddingTop: { xs: '2rem' },
      }}
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Typography data-testid="terms-title" variant="h1" component="h1">
          {t('page.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          flexDirection="row-reverse"
          spacing={2}
          justifyItems="center"
        >
          <Grid item>
            <Button
              variant="contained"
              color="success"
              data-testid="download-pdf"
              onClick={downloadPdfClickHandler}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TermsPageTitleButtons;
