import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import AccessManagementUserList from '../AccessManagementUserList';

function AccessManagementPage({ t }: Translator) {
  usePageTitle(t('sections.access-management.title'));

  const navigate = useNavigate();

  const handleInviteClick = () => {
    navigate('/account/access-management/invite');
  };

  return (
    <div data-testid="access-management-page">
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                pt: { md: '2rem' },
              }}
            >
              <Typography
                data-testid="access-management-page-title"
                variant="h1"
                component="h1"
              >
                {t('sections.access-management.title')}
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md="auto">
                  {t('sections.access-management.subtitle')}
                </Grid>
                <Grid item xs={12} md="auto" sx={{ textAlign: 'right' }}>
                  <Button
                    data-testid="invite-button"
                    variant="contained"
                    color="secondary"
                    onClick={handleInviteClick}
                    sx={{
                      backgroundColor: '#7fc832',
                      pl: '2rem',
                      pr: '2rem',
                      '&:hover': {
                        backgroundColor: '#7fc832',
                      },
                    }}
                  >
                    {t('sections.access-management.invite')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}
          >
            <Grid item xs={12} md={9}>
              <Box
                sx={{
                  pl: { md: '1rem' },
                }}
              >
                <AccessManagementUserList t={t} />
              </Box>
            </Grid>

            <Grid item xs={12} md={3} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AccessManagementPage;
