/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AddIcon from '@mui/icons-material/LibraryAdd';
import { IconButton, Link, Tooltip } from '@mui/material';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  TABLE_BODY_CELL_PROPS,
  TABLE_HEAD_CELL_PROPS,
  TABLE_HEAD_ROW_PROPS,
  TABLE_PAPER_PROPS,
  TABLE_PROPS,
} from '~/base/constants/tableConstants';
import { T } from '~/types/Translator';
import {
  SongType,
  SongTypeEdge,
  useSongsByTitleQuery,
} from '~/types/generated/graphql';
import SongStatusColumn from './SongStatusColumn';

interface SongListProps {
  searchTerm: string;
  onLoadCompleted: (res: number) => void;
  onAddRecording: (songId: string) => void;
  onCopySplits: (songId: string, song: SongType | null | undefined) => void;
  t: T;
}

function SongList({
  searchTerm,
  onLoadCompleted,
  onAddRecording,
  onCopySplits,
  t,
}: SongListProps) {
  const [loadedSongs, setLoadedSongs] = useState<SongTypeEdge[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [sortBy] = sorting;
  const { loading, error } = useSongsByTitleQuery({
    variables: {
      title_Icontains: searchTerm,
      offset: pagination.pageIndex * pagination.pageSize,
      first: pagination.pageSize,
      orderBy: sortBy ? `${sortBy.desc ? '-' : ''}${sortBy.id}` : undefined,
    },
    onCompleted: (res) => {
      const count = res.songs?.totalCount || 0;
      setLoadedSongs(res.songs?.edges as SongTypeEdge[]);
      setTotalCount(count);
      if (onLoadCompleted && typeof onLoadCompleted === 'function') {
        onLoadCompleted(count);
      }
    },
  });

  const columns = useMemo<MRT_ColumnDef<SongTypeEdge>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        enableHiding: false,
        Cell: (cellData) => {
          return (
            <Link
              to={`/songs/${cellData?.row?.original?.node?.id}/edit`}
              component={RouterLink}
              data-testid="song-title-link"
            >
              {cellData?.row?.original?.node?.title}
            </Link>
          );
        },
      },
      {
        accessorKey: 'songwriterSet',
        header: 'Songwriter(s)',
        muiTableBodyCellProps: {
          align: 'center',
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        muiTableHeadCellProps: {
          align: 'center',
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        enableSorting: false,
        enableHiding: false,
        Cell: (cellData) => {
          const songOwnerships =
            cellData?.row?.original?.node?.ownerships || [];
          const songwriterNames = songOwnerships?.reduce<string>(
            (acc, songOwnership, currentIndex) => {
              let result = acc;
              if (songOwnership) {
                result = result.concat(
                  `${songOwnership.songwriter?.firstName} ${songOwnership.songwriter?.middleName || ''} ${songOwnership.songwriter?.lastName}`,
                  currentIndex < songOwnerships.length - 1 ? ', ' : '',
                );
              }
              return result;
            },
            '',
          );
          return <span>{songwriterNames}</span>;
        },
      },
      {
        accessorKey: 'recordings',
        header: '# of Recordings',
        muiTableBodyCellProps: {
          align: 'center',
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        muiTableHeadCellProps: {
          align: 'center',
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        enableHiding: false,
        Cell: (cellData) => {
          const noOfRecordings =
            cellData?.row?.original?.node?.recordings?.totalCount || 0;
          return noOfRecordings > 0 ? (
            <span>{noOfRecordings}</span>
          ) : (
            <Tooltip title={t('form.labels.addRecording')}>
              <IconButton
                onClick={() => {
                  onAddRecording(cellData?.row?.original?.node?.id || '');
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'created',
        header: 'Date Added',
        muiTableBodyCellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        muiTableHeadCellProps: {
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        enableHiding: false,
        Cell: (cellData) => {
          const formattedDate = dayjs(
            cellData?.row?.original?.node?.created,
          ).format('MM/DD/YYYY');
          return <span>{formattedDate}</span>;
        },
      },
      {
        accessorKey: 'songlog',
        header: 'Status',
        enableHiding: false,
        Cell: (cellData) => {
          const songStatus = cellData?.row?.original?.node?.displayStatus;
          const songId = cellData?.row?.original?.node?.id;
          return (
            <SongStatusColumn
              value={songStatus}
              tooltip={t(
                `form.status.${`${songStatus}_tooltip` || 'processing_tooltip'}`,
              )}
              label={t(`form.status.${songStatus || 'processing'}`)}
              songId={songId}
            />
          );
        },
      },
      {
        header: 'Copy Splits',
        muiTableBodyCellProps: {
          align: 'center',
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        muiTableHeadCellProps: {
          align: 'center',
          sx: { display: { xs: 'none', sm: 'table-cell' } },
        },
        enableHiding: false,
        Cell: (cellData) => {
          const song = cellData?.row?.original?.node;
          return (
            <Tooltip title={t('form.labels.addSongWithSameSplits')}>
              <IconButton
                data-testid="copy-splits-button"
                onClick={() =>
                  onCopySplits(cellData?.row?.original?.node?.id || '', song)
                }
              >
                <CopyAllIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [],
  );

  const handleSortingChange = (
    updaterOrValue: SortingState | ((prevState: SortingState) => SortingState),
  ) => {
    setSorting(updaterOrValue);
  };

  return (
    <MaterialReactTable
      data-testid="song-list-table"
      manualSorting
      columns={columns}
      onSortingChange={handleSortingChange}
      data={loadedSongs}
      enableTopToolbar={false}
      enableColumnActions={false}
      enableColumnDragging={false}
      onPaginationChange={setPagination}
      rowCount={totalCount}
      state={{
        sorting,
        isLoading: loading,
        pagination,
        showAlertBanner: error !== undefined,
        showProgressBars: loading,
      }}
      muiTableHeadRowProps={TABLE_HEAD_ROW_PROPS}
      muiTableHeadCellProps={TABLE_HEAD_CELL_PROPS}
      muiTableBodyCellProps={TABLE_BODY_CELL_PROPS}
      muiTableProps={TABLE_PROPS}
      muiTablePaperProps={TABLE_PAPER_PROPS}
      manualPagination
      enableBottomToolbar
      muiCircularProgressProps={{
        color: 'secondary',
        thickness: 5,
        size: 55,
      }}
      muiSkeletonProps={{
        animation: 'pulse',
        height: 28,
      }}
    />
  );
}

export default SongList;
