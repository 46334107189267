import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import AddSongwriterStepper from '../AddSongwriterStepper/AddSongwriterStepper';

function AddSongwriterPage({ t }: Translator) {
  const [activeStep, setActiveStep] = React.useState(0);
  usePageTitle(t('page.add-songwriter.title'));

  return (
    <Container data-testid="add-songwriter-page">
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: {
            xs: 'column',
          },
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h1" component="h1" sx={{ pt: { md: '2rem' } }}>
            {t('page.add-songwriter.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ ml: '1rem' }}>
          <AddSongwriterStepper
            t={t}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddSongwriterPage;
