import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import { Translator } from '~/types/Translator';
import {
  ClientSongwriterInput,
  CreateClientSongwriterMutation,
  PublishingCompanyInput,
  useCreateClientSongwriterMutation,
  UsRepresentationInput,
} from '~/types/generated/graphql';
import AddPROInformation from '../AddPROInformation/AddPROInformation';
import AddSongtrustWriter from '../AddSongtrustWriter/AddSongtrustWriter';

interface AddSongwriterStepperProps extends Translator {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export default function AddSongwriterStepper({
  t,
  activeStep,
  setActiveStep,
}: AddSongwriterStepperProps) {
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');
  const [disableContinueButton, setDisableContinueButton] =
    useState<boolean>(true);

  const navigate = useNavigate();

  const addSongwriterFormMethods = useForm();

  const [createClientSongwriter, { loading }] =
    useCreateClientSongwriterMutation({
      fetchPolicy: 'no-cache',
    });

  const steps = ['pro', 'songwriter'];

  const handleFormError = (data: CreateClientSongwriterMutation) => {
    if (data?.createClientSongwriter?.errors?.length !== 0) {
      let errorString = '';
      data?.createClientSongwriter?.errors?.forEach((error) => {
        errorString += `\n${error?.error}`;
      });
      setProcessError(errorString);
      setWasSaved(false);
      window.scrollTo({ top: 0 });
    }
  };

  const handleSubmitError = (inError: ApolloError | undefined) => {
    if (inError) {
      setProcessError(inError.message);
      setWasSaved(false);
      window.scrollTo({ top: 0 });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    const acceptExtraTerms =
      addSongwriterFormMethods.getValues('accept-extra-terms');

    if (acceptExtraTerms === true || acceptExtraTerms === undefined) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      addSongwriterFormMethods.setError('accept-extra-terms', {
        type: 'manual',
        message: t('page.add-songwriter.form.errors.accept-terms'),
      });
    }
  };

  const handleCancel = () => {
    navigate('/songwriters/manage');
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  const handleSubmitComplete = (data: CreateClientSongwriterMutation) => {
    if (data?.createClientSongwriter?.errors?.length !== 0) {
      handleFormError(data);
    } else {
      setWasSaved(true);
      navigate('/songwriters/manage');
    }
  };

  const handleSubmit = async (values: FieldValues) => {
    setProcessError('');
    setWasSaved(false);

    if (await addSongwriterFormMethods.trigger()) {
      const hasPublishingCompany = addSongwriterFormMethods.getValues(
        'has-publishingcompany',
      );

      const usProAffiliation =
        addSongwriterFormMethods.getValues('us-pro-affiliation');

      const usProAffiliationViaPublisher = addSongwriterFormMethods.getValues(
        'us-pro-affiliation-via-publisher',
      );

      const songwriterYoutubeClaimsVal = addSongwriterFormMethods.getValues(
        'songwriter-youtube-claims',
      );

      const birthdateFormatted = dayjs(
        addSongwriterFormMethods.getValues('birthdate') as string,
      ).format('YYYY-MM-DD');

      const usRepresentationInput: UsRepresentationInput = {
        name: values['pro-representation'] || 'ASCAP',
      };

      const publishingCompanyInput: PublishingCompanyInput = {
        name: values['publishingcompany-name'],
        ipi: values['publishingcompany-ipi'],
        pro: { name: values['publishingcompany-pro'] },
      };

      const addSongwriterInput: ClientSongwriterInput = {
        songwriterType: 'client',
        email: values.email,
        firstName: values.firstname,
        ipi: values.ipi,
        lastName: values.lastname,
        birthdate: birthdateFormatted,
        songwriterYoutubeClaims: songwriterYoutubeClaimsVal === 'yes',
        pro: {
          name: values.pro,
        },
        signature: values.signature,
        usRepresentation:
          usProAffiliation === 'yes' || usProAffiliationViaPublisher === 'yes'
            ? usRepresentationInput
            : undefined,
        publishingCompany:
          hasPublishingCompany === 'no' ? undefined : publishingCompanyInput,
        lodTosAccepted: true,
      };

      // Submit Mutation.
      createClientSongwriter({
        variables: {
          input: addSongwriterInput,
        },
        onCompleted: handleSubmitComplete,
        onError: handleSubmitError,
      });
    }
  };

  return (
    <Box sx={{ width: '100%' }} data-testid="onboarding-stepper">
      <FormProvider {...addSongwriterFormMethods}>
        <Box sx={{ pb: 4 }}>
          {!wasSaved && (
            <FormBanner text={processError} type={FormBannerType.ERROR} />
          )}
          {wasSaved && (
            <FormBanner
              text={t('page.add-songwriter.form.success')}
              type={FormBannerType.SUCCESS}
              time={2000}
            />
          )}
        </Box>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  sx={{
                    '.MuiStepLabel-label': {
                      display: {
                        xs: index !== activeStep ? 'none' : 'block',
                        md: 'block',
                      },
                    },
                  }}
                >
                  {t(`page.add-songwriter.form.steps.${label}`)}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button>{t('form.buttons.reset')}</Button>
            </Box>
          </>
        ) : (
          <form
            id="addSongwriterForm"
            onSubmit={addSongwriterFormMethods.handleSubmit(handleSubmit)}
          >
            {activeStep === 0 && (
              <Grid item xs={12} sx={{ ml: '1rem' }}>
                <AddPROInformation
                  t={t}
                  setDisableContinueButton={setDisableContinueButton}
                />
              </Grid>
            )}

            {activeStep === 1 && (
              <Grid item xs={12} sx={{ ml: '1rem' }}>
                <AddSongtrustWriter t={t} />
              </Grid>
            )}

            <Grid item xs={12} md={8} sx={{ ml: '1rem' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {activeStep === 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    data-testid="button-cancel"
                    sx={{ mr: 1 }}
                    onClick={handleCancel}
                  >
                    {t('page.add-songwriter.form.buttons.cancel')}
                  </Button>
                )}
                {activeStep !== 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    data-testid="button-back"
                    disabled={activeStep === 0}
                    sx={{ mr: 1 }}
                    onClick={handleBack}
                  >
                    {t('page.add-songwriter.form.buttons.back')}
                  </Button>
                )}
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep === steps.length - 1 && !loading && (
                  <Button
                    // disabled={!addSongwriterFormMethods.formState.isValid}
                    variant="contained"
                    color="secondary"
                    data-testid="button-submit"
                    type="submit"
                    name="button-submit"
                  >
                    {t('page.add-songwriter.form.buttons.submit')}
                  </Button>
                )}
                {activeStep !== steps.length - 1 && (
                  <Button
                    data-testid="button-continue"
                    variant="contained"
                    color="secondary"
                    disabled={disableContinueButton}
                    onClick={handleNext}
                  >
                    {t('page.add-songwriter.form.buttons.continue')}
                  </Button>
                )}
              </Box>
            </Grid>
          </form>
        )}
      </FormProvider>
    </Box>
  );
}
