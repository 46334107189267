import { ApolloClient, HttpLink, InMemoryCache, makeVar } from '@apollo/client';
import fetch from 'cross-fetch';

import { relayStylePagination } from '@apollo/client/utilities';
import { SessionToken } from '~/types/SessionToken.model';
import {
  DashboardDataQuery,
  DashboardEarningsQuery,
  FeatureFlagsAndSwitchesQuery,
  LoggedInSongtrustUserPersonaQuery,
  OnboardingFieldsQuery,
  SubscriptionPlanQuery,
} from './types/generated/graphql';

// Setup default backend location where data calls go to.
const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT_URL,
  credentials: 'include',
  fetch,
});

// Default cache settings.
const defaultCache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        countries: relayStylePagination(),
        loggedInSongtrustUser: { merge: true },
      },
    },
  },
});

// Exported constants.
export const defaultAuthRefreshInterval = 1800000; // 30 min, backend max is 45
export const defaultClient = new ApolloClient({
  link: httpLink,
  cache: defaultCache,
  defaultOptions: {
    watchQuery: {
      initialFetchPolicy: 'cache-and-network',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  },
});

// Global cached reactive variables.
export const currentSessionTokenVar = makeVar<SessionToken | undefined>(
  undefined,
);

export const featureFlagsVar = makeVar<
  FeatureFlagsAndSwitchesQuery | undefined
>(undefined);

export const currentSongtrustUserPersonaVar = makeVar<
  LoggedInSongtrustUserPersonaQuery | undefined
>(undefined);

export const currentSongtrustUserSubscriptionPlanVar = makeVar<
  SubscriptionPlanQuery | undefined
>(undefined);

export const loggedInSongtrustUserOnboardingResponseVar = makeVar<
  OnboardingFieldsQuery | undefined
>(undefined);

export const dashboardResponseVar = makeVar<DashboardDataQuery | undefined>(
  undefined,
);

export const dashboardEarningsVar = makeVar<DashboardEarningsQuery | undefined>(
  undefined,
);
