import { ApolloError } from '@apollo/client';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
  defaultClient,
} from '~/cache';

import {
  LogoutUserMutation,
  useLogoutUserMutation,
} from '~/types/generated/graphql';

function useLogout(
  callbackSuccess?: (data: LogoutUserMutation) => void,
  callbackError?: (error: ApolloError) => void,
) {
  const [doLogout] = useLogoutUserMutation({
    fetchPolicy: 'no-cache',
  });

  const handleLogoutComplete = (data: LogoutUserMutation) => {
    defaultClient.stop();
    defaultClient.resetStore().then(() => {
      currentSessionTokenVar(undefined);
      currentSongtrustUserPersonaVar(undefined);
      if (callbackSuccess) callbackSuccess(data);
    });
  };

  const handleLogoutError = (error: ApolloError) => {
    defaultClient.stop();
    defaultClient.resetStore().then(() => {
      currentSessionTokenVar(undefined);
      currentSongtrustUserPersonaVar(undefined);
      if (callbackError) callbackError(error);
    });
  };

  const logoutUser = async () => {
    await doLogout({
      onCompleted: handleLogoutComplete,
      onError: handleLogoutError,
    });
  };

  return [logoutUser];
}

export default useLogout;
