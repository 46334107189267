import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import accountConstants, {
  ACCOUNT_KYC_PATHS,
  ACCOUNT_PATHS,
} from '~/account/constants/accountConstants';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';
import {
  DASHBOARD_PATHS,
  FROZEN_PATHS,
  PASSWORD_RESET_PATHS,
} from '~/dashboard/constants/dashboardConstants';
import useLoginRefresh from '~/login/hooks/useLoginRefresh';
import { ONBOARDING_PATHS } from '~/onboarding/constants/onboardingConstants';
import { RefreshTokenMutation } from '~/types/generated/graphql';

const TAX_INFO_REQUIRED_ALLOWED_PATHS = [...DASHBOARD_PATHS, ...ACCOUNT_PATHS];

function useRequireAuth() {
  const [requireAuthComplete, setRequireAuthComplete] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const loggedInUserPersona = useReactiveVar(currentSongtrustUserPersonaVar);

  const userRequiresKYC =
    loggedInUserPersona?.loggedInSongtrustUser?.isKycVerificationRequired;

  const userIsFrozen =
    loggedInUserPersona?.loggedInSongtrustUser?.frozenOn !== null &&
    loggedInUserPersona?.loggedInSongtrustUser?.frozenOn !== undefined;

  const passwordChangeRequested =
    loggedInUserPersona?.loggedInSongtrustUser?.requiresPasswordChange;

  const onboardingRequested =
    loggedInUserPersona?.loggedInSongtrustUser?.requiresOnboarding;

  const isGhostedAdmin =
    loggedInUserPersona?.loggedInSongtrustUser?.isGhostedAdmin;

  const hasPaymentAndTaxInformation =
    loggedInUserPersona?.loggedInSongtrustUser?.paymentAndTaxInformation;

  const mustUpdateTaxInfo =
    // Bypass if account type does not require tax and payment information
    !(
      hasPaymentAndTaxInformation &&
      !hasPaymentAndTaxInformation.doesAccountTypeRequireTaxAndPaymentInformation
    ) &&
    // Check if user has provided tax and payment information
    !(
      hasPaymentAndTaxInformation &&
      hasPaymentAndTaxInformation.hasPaymentMethod &&
      hasPaymentAndTaxInformation.hasTaxInformation &&
      hasPaymentAndTaxInformation.hasValidAccount
    );
  const hasSongs = loggedInUserPersona?.loggedInSongtrustUser?.hasSongs;

  const handleLoginRefreshSuccess = (data: RefreshTokenMutation) => {
    if (!data.refreshToken) {
      navigate('/login');
    }
  };
  const handleLoginRefreshError = () => {
    navigate('/login');
  };

  const [loginRefresh] = useLoginRefresh(
    handleLoginRefreshError,
    handleLoginRefreshSuccess,
  );

  const routeUserFrozen = () => {
    if (!FROZEN_PATHS.includes(pathname)) navigate('/frozen');
  };

  const routePasswordChangeRequested = () => {
    if (!PASSWORD_RESET_PATHS.includes(pathname)) {
      navigate('/account/reset-requested');
    }

    setRequireAuthComplete(true);
  };

  const routeOnboardingRequested = () => {
    if (!ONBOARDING_PATHS.includes(pathname)) {
      navigate('/get-started');
    }

    setRequireAuthComplete(true);
  };

  const routeKYCRequired = () => {
    if (!ACCOUNT_KYC_PATHS.includes(pathname)) {
      navigate({
        pathname: accountConstants.kycVerificationRoute,
      });
    }

    setRequireAuthComplete(true);
  };

  const routeTaxInfoRequired = () => {
    if (!TAX_INFO_REQUIRED_ALLOWED_PATHS.includes(pathname)) {
      navigate({
        pathname: accountConstants.taxSettingsRoute,
        search: '?q=showDialog',
      });
    }

    setRequireAuthComplete(true);
  };

  useEffect(() => {
    // No session token, refresh token or go to login.
    if (!sessionToken) {
      loginRefresh();
    }

    // Redirect cases:
    // 1. User's account has been frozen.
    if (userIsFrozen && !isGhostedAdmin) {
      routeUserFrozen();
      return;
    }

    // 2. User is required to change their password.
    if (passwordChangeRequested && !isGhostedAdmin) {
      routePasswordChangeRequested();
      return;
    }

    // 3. User needs to do onboarding form.
    if (onboardingRequested) {
      routeOnboardingRequested();
      return;
    }

    // 4. User needs to verify KYC.
    if (userRequiresKYC && !isGhostedAdmin) {
      routeKYCRequired();
      return;
    }

    // 5. User needs to update tax info.
    if (mustUpdateTaxInfo && hasSongs && !isGhostedAdmin) {
      routeTaxInfoRequired();
      return;
    }

    // No special cases, user is good to go.
    setRequireAuthComplete(true);
  }, [loginRefresh]);

  return { requireAuthComplete };
}

export default useRequireAuth;
