import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PercentIcon from '@mui/icons-material/Percent';
import SearchIcon from '@mui/icons-material/Search';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import FormInput from '~/base/components/FormInput';
import { Translator } from '~/types/Translator';
import {
  SongtrustUserType,
  SongwriterType,
  useCurrentUserQuery,
  useSongSongwritersLazyQuery,
} from '~/types/generated/graphql';
import {
  useFormContext,
  useFieldArray,
  UseFieldArrayRemove,
} from 'react-hook-form';
import { alternateSongwriter, findSameName, nameArray } from './utils';

interface SelectedSongwriterEditProps extends Translator {
  i: number;
  remove: UseFieldArrayRemove;
}

export default function SelectedSongwriterEdit({
  i,
  remove,
  t,
}: SelectedSongwriterEditProps) {
  const { data: currentUser } = useCurrentUserQuery();
  const { setValue, watch } = useFormContext();
  const songwriter = watch('songwriter');
  const cowriters = watch('cowriters');
  const cowriter = cowriters[i];
  const overclaiming = watch('overclaiming');
  const { update } = useFieldArray({ name: 'cowriters' });
  const [fetchSongwriters, { data }] = useSongSongwritersLazyQuery();
  const maxValue = overclaiming === 'yes' ? 99.99 : 100;

  const [songwriterEdit, setSongwriterEdit] = useState({
    songwriterId: cowriter.songwriterId,
    label: cowriter.label,
    percent: null as unknown as number,
    isOutsideSongwriter: false,
  });

  const [currentInputValue, setCurrentInputValue] = useState(
    cowriter?.label || t('form.placeholders.cowriter'),
  );

  useEffect(() => {
    if (Number(cowriter.percent) > maxValue) {
      setSongwriterEdit((state) => ({ ...state, percent: maxValue }));
      setValue(`cowriters.${i}.percent`, maxValue);
    }
  }, [cowriter.percent, i, maxValue, overclaiming]);

  const renderLabel = (s: string | null | undefined, a?: boolean): string => {
    const v = a ? `(${s})` : s;
    return s ? ` ${v}` : '';
  };

  return (
    <>
      <Grid
        container
        direction="row"
        xs={12}
        gap={1}
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          justifyContent: 'space-between',
        }}
      >
        <Grid container xs={7}>
          <FormAutoComplete
            id="cowriter-input"
            testid="cowriter-container"
            sx={{
              width: '100%',
            }}
            label=""
            name={`cowriters.${i}.songwriterId`}
            inputValue={currentInputValue}
            options={
              data?.songwriters?.edges
                ?.map((edge, _, edges) => {
                  const alteration = renderLabel(
                    alternateSongwriter(
                      edge?.node as SongwriterType,
                      edges
                        .filter(
                          (e) =>
                            !nameArray(e?.node as SongwriterType).every(
                              (v, index) =>
                                v ===
                                nameArray(edge?.node as SongwriterType)[index],
                            ),
                        )
                        .some((e) =>
                          findSameName(
                            nameArray(e?.node as SongwriterType),
                            nameArray(edge?.node as SongwriterType),
                          ),
                        ),
                      currentUser?.loggedInSongtrustUser as SongtrustUserType,
                      t,
                    ),
                    true,
                  );
                  return {
                    choiceId: edge?.node?.id ?? '',
                    choiceLabel:
                      `${[
                        ...nameArray(edge?.node as SongwriterType),
                        alteration,
                      ].reduce(
                        (s: string, c) => s.concat(renderLabel(c)),
                        '',
                      )}` ?? '',
                  };
                })
                .filter((c) => {
                  const s = [songwriter]
                    .concat(cowriters)
                    .map((o) => o.songwriter);
                  return !s.includes(c.choiceId);
                }) ?? []
            }
            autocompleteSX={{
              '.MuiAutocomplete-popupIndicator': {
                transform: 'none',
              },
              width: '100%',
            }}
            popupIcon={<SearchIcon fontSize="small" />}
            forcePopupIcon
            onOptionSelected={(id, label) => {
              const outside = new RegExp(t('form.outside', 'i'));
              setSongwriterEdit((state) => ({
                ...state,
                label,
                songwriterId: id,
                isOutsideSongwriter: outside.test(label),
              }));
            }}
            inputProps={{ 'data-testid': `cowriter-input-${i}` }}
            onInputChange={(_: React.SyntheticEvent, value: string) => {
              setCurrentInputValue(value);
              fetchSongwriters({ variables: { searchFor: value } });
            }}
            freeSolo
          />
        </Grid>
        <Grid container xs={4.25}>
          <FormInput
            id="songwriter-percentage"
            label=""
            name={`cowriters.${i}.percent`}
            inputProps={{
              'data-testid': `cowriter-percent-${i}`,
              type: 'number',
              min: 0,
              max: 100,
              step: 0.01,
            }}
            endAdornment={<PercentIcon fontSize="small" />}
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        padding={2}
        paddingRight={2}
        gap={2}
      >
        <Grid container flexBasis="min-content">
          <Button
            variant="outlined"
            onClick={() => {
              if (!cowriter?.saved) remove();
              else setValue(`cowriters.${i}.edit`, false);
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid container flexBasis="min-content">
          <Button
            data-testid={`cowriter-submit-${i}`}
            variant="contained"
            disabled={
              !(
                songwriterEdit.songwriterId &&
                songwriterEdit.label &&
                cowriter.percent
              )
            }
            onClick={() => {
              const {
                songwriterId: s,
                label,
                isOutsideSongwriter,
              } = songwriterEdit;
              const { percent } = cowriter;
              if (s && label && percent)
                update(i, {
                  songwriterId: parseInt(s, 10),
                  label,
                  percent,
                  isOutsideSongwriter,
                  saved: true,
                  edit: false,
                });
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
