import { Box } from '@mui/material';
import React from 'react';

interface SongtrustTabPanelProps {
  /** The content to be displayed inside the panel */
  children?: React.ReactNode;
  /** The unique identifier for the panel */
  id: string;
  /** The value to match the panel's id to determine visibility */
  value: string | undefined;
}

/**
 * SongtrustTabPanel is a component used to display a tab panel in a tabbed interface.
 * It hides or shows its content based on whether its id matches the current value.
 *
 * @param {SongtrustTabPanelProps} props - The properties for the SongtrustTabPanel component.
 * @returns {JSX.Element} The rendered SongtrustTabPanel component.
 */
function SongtrustTabPanel({
  children,
  value,
  id,
  ...other
}: SongtrustTabPanelProps): JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      data-testid={`songtrust-tabpanel-${id}`}
      aria-labelledby={`songtrust-tab-${id}`}
      {...other}
    >
      {value === id && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default SongtrustTabPanel;
