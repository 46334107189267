import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {
  Button,
  FormControl,
  FormHelperText,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import FormInput from '~/base/components/FormInput';
import { currentSessionTokenVar } from '~/cache';
import { SessionToken } from '~/types/SessionToken.model';
import { Translator } from '~/types/Translator';
import {
  LoginUserMutation,
  useLoginUserMutation,
} from '~/types/generated/graphql';

function LoginPageForm({ t }: Translator) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loginUser, { loading, error }] = useLoginUserMutation({
    fetchPolicy: 'network-only',
  });

  const formMethods = useForm();

  const handleLoginComplete = (data: LoginUserMutation) => {
    const sessionToken: SessionToken = {
      token: data?.tokenAuth?.token as string,
      refreshToken: data?.tokenAuth?.refreshToken as string,
      refreshExpiresIn: data?.tokenAuth?.refreshExpiresIn as number,
    };

    currentSessionTokenVar(sessionToken);

    const next = searchParams.get('next');
    const { hash } = window.location;
    if (next) {
      const navigateTo = `${import.meta.env.VITE_LEGACY_APP_AUTH_REDIRECT}${next}${hash}`;
      window.location.replace(navigateTo);
    } else {
      navigate('/dashboard');
    }
  };

  const loginClickHandler = async (values: FieldValues) => {
    const fp = await FingerprintJS.load();
    const fpResult = await fp.get();

    loginUser({
      variables: {
        fp: fpResult.visitorId,
        username: values.email,
        password: values.password,
      },
      onCompleted: handleLoginComplete,
      onError: () => undefined,
    });
  };

  const loginTextFieldStyle = {
    marginBottom: '2rem',
    '& .MuiOutlinedInput-root': {
      marginTop: '1rem',
      '& > fieldset': {
        border: '2px solid #7E7E7E',
        borderRadius: '.5rem',
      },
      '&.Mui-focused, &:hover': {
        '& > fieldset': {
          borderColor: '#031C9B',
        },
      },
    },
  };

  return (
    <div className="login-page-form" data-testid="login-page-form">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(loginClickHandler)}>
          <Stack direction="column">
            <Typography
              variant="h1"
              component="h2"
              sx={{ textAlign: 'center', marginBottom: '2rem' }}
            >
              {t('form.title')}
            </Typography>

            <FormInput
              sx={{
                width: '70%',
                marginLeft: 'auto',
                marginRight: 'auto',
                ...loginTextFieldStyle,
              }}
              id="login-username"
              name="email"
              label={t('form.email')}
              required={{
                value: true,
                message: t('form.validation.email.required'),
              }}
              validate={{
                isEmailValid: (v) =>
                  validator.isEmail(v as string) ||
                  t('form.validation.email.invalid'),
              }}
            />

            <FormInput
              sx={{
                width: '70%',
                marginLeft: 'auto',
                marginRight: 'auto',
                ...loginTextFieldStyle,
              }}
              label={t('form.password')}
              name="password"
              id="login-password"
              required={{
                value: true,
                message: t('form.validation.password.required'),
              }}
              type="password"
            >
              <Link
                component="a"
                href="/forgot-password"
                data-testid="forgot-password-link"
                sx={{
                  textAlign: 'right',
                  top: '-0.75rem',
                  right: '0rem',
                  zIndex: '999',
                  position: 'absolute',
                }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  navigate('/forgot-password');
                }}
              >
                {t('form.forgot_password')}
              </Link>
            </FormInput>

            <FormControl
              sx={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <Button
                data-testid="login-button"
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                }}
              >
                {t('form.login')}
              </Button>
              <FormHelperText
                sx={{
                  color: error ? '#F33126' : 'inherit',
                  fontSize: '.75rem',
                  textAlign: 'center',
                }}
              >
                {loading ? <span>Logging in...</span> : null}
                {error ? (
                  <span data-testid="login-error-message">
                    Oh no! {error.message}
                  </span>
                ) : null}
              </FormHelperText>
            </FormControl>
          </Stack>
        </form>
      </FormProvider>
    </div>
  );
}

export default LoginPageForm;
