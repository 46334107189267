/* eslint-disable react/require-default-props */
import {
  Autocomplete,
  FormControl,
  Grid,
  InputBaseComponentProps,
  InputLabel,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormTooltip from '~/base/components/FormTooltip';

interface FormAutoCompleteOption {
  choiceId: string;
  choiceLabel: string;
  disabled?: boolean;
}

interface FormAutoCompleteProps {
  id: string;
  label: string;
  name: string;
  testid: string;
  tooltip?: string;
  sx?: SxProps<Theme>;
  autocompleteSX?: SxProps<Theme>;
  disabled?: boolean;
  disablePortal?: boolean;
  displayRequired?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  options: FormAutoCompleteOption[];
  placeholder?: string;
  popupIcon?: React.ReactNode;
  forcePopupIcon?: 'auto' | boolean;
  freeSolo?: boolean;
  onControlledOptionSelected?: (
    label: string,
    value?: string,
  ) => void | undefined;
  onOptionSelected?: (value: string, label: string) => void | undefined;
  onInputChange?: (
    evt: React.SyntheticEvent,
    value: string,
  ) => void | undefined;
  inputProps?: InputBaseComponentProps | undefined;
  inputValue?: string | undefined;
  loading?: boolean;
}

function FormAutoComplete({
  id,
  name,
  label,
  testid,
  tooltip = undefined,
  sx = {},
  autocompleteSX = {},
  disabled = false,
  disablePortal = true,
  displayRequired = false,
  required = false,
  children = [],
  options,
  placeholder = '',
  popupIcon = undefined,
  forcePopupIcon = false,
  freeSolo = false,
  onControlledOptionSelected = undefined,
  onOptionSelected = undefined,
  onInputChange = () => undefined,
  inputProps,
  inputValue = undefined,
  loading = undefined,
}: FormAutoCompleteProps) {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const { onChange, onBlur, ref } = register(name, { required });
  const error = name in errors ? errors[name] : '';
  const currentValue = getValues(name);
  const initValue = options.find((item) => item.choiceId === currentValue);
  const [displayValue, setDisplayValue] = useState(
    initValue ?? {
      choiceId: '',
      choiceLabel: '',
    },
  );

  return (
    <FormControl sx={sx} variant="standard">
      {children}
      <InputLabel htmlFor={id} shrink={false} sx={{ width: '100%', zIndex: 1 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {label}{' '}
            {displayRequired ? (
              <span style={{ color: '#ba3310' }}>
                &#65290;
                <span style={{ display: 'none' }}>required</span>
              </span>
            ) : (
              ''
            )}
          </Grid>
          <Grid item>{tooltip && <FormTooltip tooltip={tooltip} />}</Grid>
        </Grid>
      </InputLabel>

      <Autocomplete
        disablePortal={disablePortal}
        id={id}
        options={options}
        onBlur={onBlur}
        ref={ref}
        disabled={disabled}
        value={displayValue}
        sx={{
          width: 300,
          ...autocompleteSX,
        }}
        loading={loading}
        getOptionLabel={(field: string | FormAutoCompleteOption) => {
          return (typeof field === 'string' ? field : field.choiceLabel) || '';
        }}
        popupIcon={popupIcon}
        forcePopupIcon={forcePopupIcon}
        freeSolo={freeSolo}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.choiceId}>
              {option.choiceLabel}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            data-testid={testid}
            {...(params as StandardTextFieldProps)}
            placeholder={placeholder}
            error={!!error}
            inputProps={{
              ...params.inputProps,
              ...(inputProps || {}),
              ...(inputValue ? { value: inputValue } : {}),
            }}
          />
        )}
        onChange={(event, value) => {
          if (onControlledOptionSelected && value) {
            onControlledOptionSelected(
              (value as FormAutoCompleteOption)?.choiceId,
              (value as FormAutoCompleteOption)?.choiceLabel,
            );
          } else if (onOptionSelected) {
            const storedValue = (value as FormAutoCompleteOption)?.choiceId;
            const storedLabel = (value as FormAutoCompleteOption)?.choiceLabel;

            setValue(name, storedValue);
            onOptionSelected(storedValue, storedLabel);
          }
          setDisplayValue({
            choiceId: (value as FormAutoCompleteOption)?.choiceId,
            choiceLabel: (value as FormAutoCompleteOption)?.choiceLabel,
          });
          return onChange(event);
        }}
        onInputChange={onInputChange}
        isOptionEqualToValue={(option, value) =>
          option.choiceId === value.choiceId
        }
      />
    </FormControl>
  );
}

export default FormAutoComplete;
