import { Box, Grid, Link, Typography } from '@mui/material';
import FormRadio from '~/base/components/FormRadio';
import SectionTipCard from '~/base/components/SectionTipCard';
import { Translator } from '~/types/Translator';

type AddSongtrustWriterYoutubeClaimsProps = Translator;
function AddSongtrustWriterYoutubeClaims({
  t,
}: AddSongtrustWriterYoutubeClaimsProps) {
  return (
    <Grid
      container
      spacing={2}
      data-testid="add-songtrust-writer-youtube-claims"
    >
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component="h1"
          sx={{ borderBottom: '1px solid #000' }}
        >
          {t('page.add-songwriter.form.songwriter.youtube.title')}
        </Typography>
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              {t('page.add-songwriter.form.songwriter.youtube.question')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormRadio
              id="songwriter-youtube-claims"
              name="songwriter-youtube-claims"
              data-testid="songwriter-youtube-claims"
              columns={1}
              required
              options={[
                {
                  choiceId: 'yes',
                  choiceLabel: t(
                    'page.add-songwriter.form.songwriter.youtube.yes',
                  ),
                },
                {
                  choiceId: 'no',
                  choiceLabel: t(
                    'page.add-songwriter.form.songwriter.youtube.no',
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12}>
        <SectionTipCard
          title={t('page.add-songwriter.form.songwriter.youtube.tip.title')}
          color="blue"
        >
          <Box sx={{ mt: 1 }}>
            <p>
              {t('page.add-songwriter.form.songwriter.youtube.tip.content')}
              {` `}
              <Link
                href={t(
                  'page.add-songwriter.form.songwriter.youtube.tip.blog-link',
                )}
              >
                {t('page.add-songwriter.form.songwriter.youtube.tip.here')}
              </Link>
            </p>
            <p>
              {t(
                'page.add-songwriter.form.songwriter.youtube.tip.content-continued',
              )}
              {` `}
              <Link
                href={t(
                  'page.add-songwriter.form.songwriter.youtube.tip.youtube-link',
                )}
              >
                {t(
                  'page.add-songwriter.form.songwriter.youtube.tip.requirements',
                )}
              </Link>
            </p>
          </Box>
        </SectionTipCard>
      </Grid>
    </Grid>
  );
}

export default AddSongtrustWriterYoutubeClaims;
