/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import AccountHistoryPageTable from '../AccountHistoryPageTable/AccountHistoryPageTable';

function AccountHistoryPage({ t }: Translator) {
  usePageTitle(t('sections.payment-history.title'));

  return (
    <div data-testid="account-payment-history-page">
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="body1" component="p" sx={{ pt: '1rem' }}>
              <Link to="/account/tax-documentation" component={RouterLink}>
                {t('sections.payment-history.breadcrumb')}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                pt: { md: '1rem' },
              }}
            >
              <Typography
                data-testid="account-payment-history-page-title"
                variant="h1"
                component="h1"
              >
                {t('sections.payment-history.title')}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={{ pl: { md: '2rem' } }}>
              <Grid item xs={12} md={12}>
                <Typography
                  data-testid="account-payment-history-page-content"
                  variant="body1"
                  component="p"
                >
                  {t('sections.payment-history.content')}
                  <Link component={RouterLink} to="/account/tax-documentation">
                    {t('sections.payment-history.content-link')}
                  </Link>
                  {t('sections.payment-history.content-page')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <AccountHistoryPageTable t={t} />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AccountHistoryPage;
